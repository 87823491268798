import { Pipe, PipeTransform } from '@angular/core';
import { EventService } from '../services/event/event.service';
import moment from 'moment';
import { UserService } from '../services/user/user.service';
import { CommonServiceService } from '../services/common-service.service';
import { Role } from '../constants/enums';

@Pipe({
  name: 'getOrgUpcommingEvent',
  standalone: true,
})
export class GetOrgUpcommingEventPipe implements PipeTransform {
  constructor(
    private eventService: EventService,
    private userService: UserService,
    private commonFun: CommonServiceService
  ) {}
  async transform(orgId: string): Promise<any> {
const currentDate = this.commonFun.moment().startOf('day').valueOf();
    let cycle = 2024;
    if(this.userService.studioID){
      cycle = await this.commonFun.getReportingYear(this.userService.studioID, Role.STUDIO);
    }
    let orgUpcomingEvent = (await this.eventService.getFirstEventFromGivenDate(
      currentDate,
      this.userService.studioID,
      orgId, cycle
    )).val()
    if (orgUpcomingEvent) {
      return this.commonFun.convertObjToArr(orgUpcomingEvent)[0];
    } else return null;
  }
}
