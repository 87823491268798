import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user/user.service';
import moment from 'moment';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonServiceService } from '../../shared/services/common-service.service';
import lodash from 'lodash';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../shared/services/loader/loader.service';
import { environment } from '../../../environments/environment.stage';
import { LocalStorageService } from '../../shared/services/storage/local-storage.service';
import { Role } from '../../shared/constants/enums';

@Component({
  selector: 'app-general-dropbox',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './general-dropbox.component.html',
  styleUrl: './general-dropbox.component.scss'
})
export class GeneralDropboxComponent {
  dropboxForm: FormGroup;
  files: any[] = [];
  studioId: any;
  studioName: string = '';
  isTouched: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private db: AngularFireDatabase,
    private messageService: MessageService,
    private commonFun: CommonServiceService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private localStorageService: LocalStorageService
  ) {
    this.dropboxForm = this.fb.group({
      orgName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })

    this.route.queryParams.subscribe((params: any) => {
      if ('studioId' in params) {
        this.studioId = params.studioId
      }
      if ('studioName' in params) {
        this.studioName = params.studioName
        if (this.studioName) {
          this.studioName.replace('%' ,' ')
        }
      }
      if("cycle" in params) {
        this.currentReportingYear = params.cycle;
      } else {
        this.currentReportingYear = moment().format('YYYY');
      }
    })
  }

  currentReportingYear: number|string;
  ngOnInit(){
   let generalInboxData = JSON.parse(this.localStorageService.getItem('generalInboxData'));
   if(generalInboxData){
     this.dropboxForm.patchValue({
      orgName: generalInboxData?.orgName || '',
      email: generalInboxData?.email || '',
     })
   }
  }


  get dropboxFormControls() {
    return this.dropboxForm.controls;
  }

  onFileSelected(event: any) {
    let files = event.target.files || event.dataTransfer.files;

    const disallowedExtensions: string[] = [
      ".EXE", ".PIF", ".APPLICATION", ".GADGET", ".MSI", ".MSP",
      ".COM", ".SCR", ".HTA", ".CPL", ".MSC", ".JAR", ".BAT",
      ".CMD", ".VB", ".VBS", ".VBE", ".JS", ".JSE", ".WS", ".WSF",
      ".WSC", ".WSH", ".PS1", ".PS1XML", ".PS2", ".PS2XML", ".PSC1",
      ".PSC2", ".MSH", ".MSH1", ".MSH2", ".MSHXML", ".MSH1XML",
      ".MSH2XML", ".SCF", ".LNK", ".INF", ".REG", ".DOC", ".XLS",
      ".PPT", ".DOCM", ".DOTM", ".XLSM", ".XLTM", ".XLAM", ".PPTM",
      ".POTM", ".PPAM", ".PPSM", ".SLDM"
    ];

    const filteredFiles: File[] = [];
    let selectedFilesCount = 0;
  
    for (const file of files) {
      if (selectedFilesCount >= 10) {
        this.openConfirmationDialog('Alert', 'You can upload up to 10 files at a time.', 'Ok', null, null, true, false, null, null)
        break;
      }
  
      const extension = file.name.split('.').pop()?.toUpperCase();
      if (!extension || disallowedExtensions.includes(`.${extension}`)) {
        this.messageService.add({
          severity: 'error',
          summary: 'File Not Supported',
          detail: `The file '${file.name}' is not supported.`,
          life: 5000
        });
        continue;
      }
  
      filteredFiles.push(file);
      selectedFilesCount++;
    }
  
    this.files = Array.from(filteredFiles);
  }
  
  onDragOver(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }
  }
  
  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
      this.onFileSelected(event);
    }
  }

  async onSubmit() {
    try {
      this.dropboxForm.markAllAsTouched();
      this.isTouched = true;
  
      if (this.dropboxForm.invalid || !this.files?.length || !this.studioId) {
        return;
      }
  
      this.loaderService.show();
  
      const pushId = this.commonFun.createFirebasePushId();
      const photoPath = `portal/generalDropBoxData/${this.studioId}/${pushId}/`;
      const uploadAlbumPhotos = async (signedURLData, file) => {
        try {
          if (!signedURLData || !signedURLData.signedUrl || !signedURLData.fileName || !file) {
            throw new Error('Invalid data for upload.');
          }
  
          const [res, imageDataUploadErr] = await this.commonFun.executePromise(
            lastValueFrom(this.commonFun.uploadImageOnS3(signedURLData.signedUrl, file))
          );
  
          if (imageDataUploadErr) {
            throw imageDataUploadErr;
          }
  
          const photoURL = `${environment.awsImageUpload.awsImageEndPoint}/${photoPath}${signedURLData.fileName}`;
  
          const fileObj = {
            createdAt: moment().valueOf(),
            downloadUrl: photoURL,
            email: this.dropboxForm.get('email').value || '',
            fileName: file.name || '',
            orgName: this.dropboxForm.get('orgName').value || '',
            fileType: (file.name && file.type) ? lodash.chain(file.name).split('.').last().value() : '',
            fileSize: this.convertFileSize(file?.size || 0)
          };
  
          await this.uploadGeneralDataToDb(fileObj);
  
          return { success: true, fileObj };
        } catch (error) {
          throw error;
        }
      };
  
      // Prepare request body for getting signed URLs
      const getSignedURLsReqBody = {
        extensions: this.files.map(file => (file.name && file.type) ? lodash.chain(file.name).split('.').last().value() : 'csv'),
        path: photoPath
      };
  
      // Get signed URLs
      const [signedURLsData, signedURlsErr] = await this.commonFun.executePromise(
        lastValueFrom(this.commonFun.generateS3SignUrl(getSignedURLsReqBody))
      );
  
      if (signedURlsErr) {
        throw signedURlsErr;
      }
  
      // Upload individual photos
      const simultaneousReqsCount = 180;
      const signedURLsLinkedDataChunks = lodash.chunk(this.files.map((file, index) => ({
        file,
        cycle: this.currentReportingYear,
        fileFormat: getSignedURLsReqBody.extensions[index]
      })), simultaneousReqsCount);
      const signedURLsDataChunks = lodash.chunk(signedURLsData, simultaneousReqsCount);
  
      let successCount = 0;
      let failureCount = 0;
  
      for (let index = 0; index < signedURLsLinkedDataChunks.length; index++) {
        const signedURLsLinkedDataChunk = signedURLsLinkedDataChunks[index];
        const signedURLsDataChunk = signedURLsDataChunks[index];
  
        await Promise.all(signedURLsLinkedDataChunk.map(async (data, i) => {
          try {
            const signedURLData = signedURLsDataChunk[i];
            if (!signedURLData) return;
  
            const photoUpdateRes = await uploadAlbumPhotos(signedURLData, data.file);
  
            if (photoUpdateRes.success) {
              successCount++;
            } else {
              failureCount++;
            }
          } catch (error) {
            console.error('Error while uploading photo:', error);
            failureCount++;
          }
        }));
      }
  
      // Send notification
      const orgName = this.dropboxForm.get('orgName').value || '';
      const message = `New ${(successCount > 1) ? `${successCount} files` : 'file'} received from ${orgName}.`;
      await this.commonFun.sendNotification(this.studioId, message, 'General Data Drops');
  
      // Reset form and display success message
      // this.dropboxForm.reset();
      // this.localStorageService.removeItem('generalInboxData')

     let successMessage = `${successCount} File${successCount !== 1 ? 's' : ''} uploaded successfully${(failureCount > 0) ? ', ' : '. '}`;
     let failureMessage = '';
     if (failureCount > 0) {
       failureMessage = `and ${failureCount} file${failureCount !== 1 ? 's' : ''} failed.`;
     }
     
     this.messageService.add({ 
       severity: 'success', 
       summary: 'Success', 
       detail: `${successMessage}${failureMessage}`
     });
     
      this.files = [];
      this.isTouched = false;
  
    } catch (error) {
      console.error('Error:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error?.error?.message || 'Error while uploading file, please try again after sometime.'
      });
  
    } finally {
      this.loaderService.hide();
    }
  }
  
  async uploadGeneralDataToDb(dataObj) {
    await this.db.list(`/portal/generalDropBoxData/${this.studioId}`).push(dataObj);
  }

  convertFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }
  
  storeValInLocalStorage() {
    let value = JSON.stringify(this.dropboxForm.value)
    this.localStorageService.setItem('generalInboxData', value)
  }
}
