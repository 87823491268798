import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ScheduleConfigurationCalendarComponent } from '../../../shared/components/schedule-configuration-calendar/schedule-configuration-calendar.component';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarModule,
} from 'angular-calendar';
import { MsgMedium, SlotStatus } from '../../../shared/constants/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../../shared/services/common-service.service';
import moment from 'moment';
import {
  Individual,
  IndividualApiService,
} from '../../../shared/services/individuals/individual.service';
import { EventService } from '../../../shared/services/event/event.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../shared/services/user/user.service';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import lodash from 'lodash';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { environment } from '../../../../environments/environment.stage';
import { ApiService } from '../../../shared/services/api/api.service';
import xlsx from 'json-as-xlsx';
import { AddIndividualComponent } from '../../../studio/event/add-individual/add-individual.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-event-booking-info',
  standalone: true,
  imports: [
    SharedModule,
    ScheduleConfigurationCalendarComponent,
    CalendarModule,
    AddIndividualComponent,
    TooltipModule
  ],
  templateUrl: './event-booking-info.component.html',
  styleUrl: './event-booking-info.component.scss',
})
export class EventBookingInfoComponent {
  @Input() allInds: any[] = [];
  @Input() eventData: any;
  @Input() isFromPublicPage: boolean = false;

  viewDate = new Date();
  slots: any[] = [];
  tracks: any[] = [];
  eventId: any;
  orgId: any;
  subjects: any[] = [];
  appointmentStatus = [
    { value: SlotStatus.CONFIRMED, key: 'Confirmed' },
    { value: SlotStatus.DECLINED, key: 'Declined' },
    { value: SlotStatus.PROPOSED, key: 'Proposed' },
  ];
  sittings = [
    { label: 'Future', value: 'future' },
    { label: 'Complete', value: 'complete' },
    { label: 'No Show', value: 'noShow' },
  ];
  currentSlotData: any = {
    individual: {
      name: '',
      grade: '',
    },
    sitting: '',
    startTimeHour: '',
    endTimeHour: '',
  };
  eventSubscriber: Subscription;
  slotPreferredDate = [];
  slotPreferredTime: any;
  selectedPreferredDate: any;
  withoutIndIdSlotData: any;
  selectedTimeSlot: any;
  isShowRescheduleSection: boolean = false;
  isShowErrorMassage: boolean = false;
  nonInviteIndList: Individual[] = [];
  previousSlot: any;
  isShowErrMsg: boolean = false;
  activeSlot: any;
  event: any;
  eventStartDate;
  eventEndDate;
  searchSlot: string = null;
  searchSlotList: any[] = [];
  showAddEditIndModel: boolean = false;
  studioId: any;
  isGetAllEventData: boolean = false;
  isFullscreenActive: boolean = false;

  constructor(
    private commonFun: CommonServiceService,
    private individualApiService: IndividualApiService,
    private eventService: EventService,
    private userService: UserService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private db: AngularFireDatabase,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orgService: OrganizationService,
    private api: ApiService,
    private elementRef: ElementRef,
    private confirmationService: ConfirmationService
  ) {
    this.commonFun.goBackButton$.next({ isShow: true });
  }

  async ngOnInit() {
    window.addEventListener('message', event => {
      if (event.data === 'exitFullscreen') {
        this.toggleFullscreen(true)
      }
    });
    if(this.eventData && this.eventData?.event?.cycle){
      this.eventCycle = this.eventData?.event?.cycle;
    }
  }

  eventCycle: string|number;
  async ngOnChanges() {
    if (this.eventData) {
      this.eventId = this.eventData?.event?.eventId;
      this.orgId = this.eventData?.event?.orgId;
      this.studioId = this.eventData?.event?.studioId;
      await this.orgService.setOrgTimezone(this.orgId);
      this.eventCycle = this.eventData?.event?.cycle;
      if (this.allInds?.length > 0) {
        this.isGetAllEventData = true;
      } else {
        this.isGetAllEventData = false;
      }
    }

    if (this.isGetAllEventData) {
      this.getCurrentEventData();
    }
  }

  // Send message to parent
  toggleFullscreen(isFromExit: boolean = false) {
    if (!isFromExit) {
      window.parent.postMessage('toggleFullscreen', '*');
      this.isFullscreenActive = !this.isFullscreenActive
    } else {
      this.isFullscreenActive = false
    }
  }

  setNextPrevBtn() {
    this.eventStartDate = this.commonFun
      .moment(this.eventData?.event?.schedule?.eventStartDate)
      .startOf('day')
      .valueOf();
    this.eventEndDate = this.commonFun
      .moment(this.eventStartDate)
      .clone()
      .add(this.eventData?.event?.schedule?.estimatedNumberOfDays - 1, 'days')
      .valueOf();
    this.eventStartDate = this.commonFun.convertTimestampToOrgTimezoneDate(
      this.eventStartDate
    );
    this.eventEndDate = this.commonFun.convertTimestampToOrgTimezoneDate(
      this.eventEndDate
    );
  }

  async exportData() {
    let exportData: any[] = [];
    let sequence = '0';
    lodash.forEach(this.slots, (slot) => {
      if (slot.type != 'lunch') {
        sequence = (Number(sequence) + 1).toString().padStart(4, '0');
        const findTrack =
          this.event?.tracks?.find((tracks) => tracks.id == slot.trackId) || {};
        const findPhotographer =
          this.event?.photographers?.find(
            (track) => track.key == findTrack.photographerId
          ) || {};
        exportData.push({
          Sequence: sequence,
          Date: this.commonFun.moment(slot.start).format('YYYY-MM-DD'),
          Time: this.commonFun.moment(slot.startTime).format('h: mm a'),
          Photographer: findPhotographer.photographerName || '',
          Track: findTrack?.trackName || '',
          'Subject First Name': slot?.indData?.firstName || '',
          'Subject Last Name': slot?.indData?.lastName || '',
          'Subject Student Number': slot?.indData?.studentID || '',
          'Subject Email': slot?.indData?.email || '',
          'Subject Phone': slot?.indData?.mobilePhone || '',
          'Appointment Status': slot?.status_appointment || '',
          'Sit Status': slot?.status_sit || '',
          Notes: '',
        });
      }
    });
    const orgName = await this.orgService.getOrgNameByOrgID(this.orgId);
    let fileName: string = `${orgName} ${
      this.eventData?.event?.meta.displayName
    } ${this.commonFun.moment(this.eventData?.event?.meta.eventDate).format(
      'YYYYMMDD'
    )} Schedule`;

    // Xlsx File Settings
    let settings = {
      fileName: fileName,
      writeMode: 'writeFile',
    };
    // Xlsx File Data
    const exportSlotList = [
      {
        sheet: 'SlotBookings',
        columns: Object.keys(exportData[0]).map((data) => ({
          label: data,
          value: data,
        })),
        content: exportData,
      },
    ];
    // Download the Xlsx file
    xlsx(exportSlotList, settings);
  }

  getCurrentEventData() {
    this.loaderService.show();
    try {
      if (!this.orgId || !this.eventId) {
        throw new Error('Params missing!');
      }
      this.eventSubscriber = this.eventService
        .getEventListener(this.eventId, this.eventCycle, this.orgId, this.studioId)
        .subscribe({
          next: (event: any) => {
            if (event) {
              this.event = event;
              this.eventData = { event, type: 'edit' };
              this.viewDate = this.eventData.event?.schedule?.eventStartDate
              ? this.commonFun.convertTimestampToOrgTimezoneDate(this.commonFun.moment(this.eventData.event.schedule.eventStartDate).startOf('day').valueOf())
              : this.eventData.event?.start || this.commonFun.convertTimestampToOrgTimezoneDate(this.commonFun.moment().startOf('day'));
              this.setNextPrevBtn();
              if (event && event.tracks) {
                event.tracks = this.commonFun.convertObjToArr(event.tracks);
              }
              if (event.slots) {
                event.slots = this.commonFun.convertObjToArr(event.slots);
              }
              if (event.photographers) {
                event.photographers = this.commonFun.convertObjToArr(
                  event.photographers
                );
              }
              if (event.tracks && event.slots) {
                this.manageSlotList(event);
              }

              if (!!event?.schedule?.eventStartDate) {
                this.viewDate = this.viewDate
                  ? this.viewDate
                  : this.commonFun.convertTimestampToOrgTimezoneDate(event?.schedule?.eventStartDate);
              }

              if (event?.subjects) {
                this.subjects = event.subjects.map((sub) => {
                  const ind = this.getIndById(sub);
                  const indName = ind ? ind.firstName + ' ' + ind.lastName : '';
                  return ind
                    ? {
                        indId: sub,
                        indName,
                        studentID: ind.studentID,
                        indID_Client: ind.indID_Client,
                      }
                    : '';
                });
              }
              // Prepare Non-Invited Individuals
              this.nonInviteIndList = lodash.filter(
                this.allInds,
                (ind) =>
                  !lodash.includes(this.event.subjects || [], ind.DBkey) &&
                  (lodash.isEmpty(ind.accessLevelKey) ||
                    ind.accessLevelKey == 'PPO')
              );
            }
            this.loaderService.hide();
          },
          error: (err) => {
            this.loaderService.hide();
            throw err;
          },
        });
    } catch (error) {
      this.loaderService.hide();
      console.error(error);
    }
  }

  async manageSlotList(event) {
    if (event) {
      if (event?.tracks) {
        event.tracks = event.tracks.map((track) => {
          let currentPhotographer =
            event?.photographers?.find(
              (photographer) => photographer.key == track.photographerId
            ) || {};
          let trackData = {
            ...track,
            id: track.key,
            name: `${currentPhotographer?.photographerName || ''} (${
              track.trackName
            })`,
          };
          return trackData;
        });
      }

      if (event?.slots) {
        event.slots = await Promise.all(
          event.slots.map(async (slot) => {
            let { slotColor, slotStatus } = this.getSlotColorAndStatus(slot);
            let user = {
              id: slot?.trackId ? slot.trackId : '',
            };
            let indData = slot?.indID ? await this.getIndById(slot.indID) : '';
            let slotData: CalendarEvent = {
              ...slot,
              eventId: this.eventId,
              hourDuration: event?.schedule?.timePerSubject,
              dayStartHour: this.getHourFromTimestamp(
                event?.schedule?.startTime
              ),
              dayEndHour: this.getHourFromTimestamp(event?.schedule?.endTime),
              start: this.commonFun.convertTimestampToOrgTimezoneDate(slot.startTime),
              end: this.commonFun.convertTimestampToOrgTimezoneDate(slot.endTime),
              meta: {
                user: user,
              },
              indData: indData,
              // resizable: {
              //   beforeStart: true,
              //   afterEnd: true,
              // },

              color: {
                primary:
                  slot.type == 'lunch'
                    ? 'rgb(184 185 188)'
                    : slot?.key == this.activeSlot?.key
                    ? '#057a05'
                    : slotColor,
                secondary:
                  slot.type == 'lunch' ? 'rgb(184 185 188)' : slotColor,
              },
              // draggable: true,
              title: this.generateSlotTitle(slot, indData, slotStatus)
            };
            return slotData;
          })
        );
        this.slots = event.slots;
        this.searchSlotList = lodash.filter(
          event.slots,
          (slot: any) => slot.type != 'lunch' && !lodash.isEmpty(slot.indData)
        );
        this.searchSlotList = lodash.map(this.searchSlotList, (slot: any) => {
          slot.indData['indName'] =
          slot.indData.firstName + ' ' + slot.indData.lastName;
          return slot;
        });
        this.searchSlotList = lodash.sortBy(this.searchSlotList, obj => obj?.indData?.lastName?.toLowerCase()?.trim());;
        this.tracks = event.tracks;
      }
    }
  }

  getIndById(indId: string): any {
    return this.allInds.find(
      (ind) => ind._key === indId || ind.DBkey === indId
    );
  }

  getSlotByIndId(indId) {
    let previousSlot = this.slots.find((slot) => slot.indID === indId);
    this.previousSlot = previousSlot;
  }

  async onSlotClick(slot, isFromSearchIndDropDown: boolean = false) {
    if (!isFromSearchIndDropDown) {
      this.searchSlot = null;
    }
    return new Promise<void>(async (resolve, reject) => {
      try {
        let currentSlot = slot?.event || slot;
        if (currentSlot && currentSlot.type != 'lunch') {
          this.selectedPreferredDate = null;
          this.selectedTimeSlot = null;
          this.activeSlot = currentSlot;
          this.slots = this.slots.map((slot) => {
            let { slotColor, slotStatus } = this.getSlotColorAndStatus(slot);
            let indData = slot?.indID ? this.getIndById(slot.indID) : '';
            if (slot?.key == currentSlot?.key) {
              slot.color = {
                primary: slot.type == 'lunch' ? 'rgb(184 185 188)' : '#057a05',
                secondary:
                  slot.type == 'lunch' ? 'rgb(184 185 188)' : slotColor,
              };
              slot.title = this.generateSlotTitle(slot, indData, slotStatus);
            } else {
              slot.color = {
                primary: slot.type == 'lunch' ? 'rgb(184 185 188)' : slotColor,
                secondary:
                  slot.type == 'lunch' ? 'rgb(184 185 188)' : slotColor,
              };
            }
            return slot;
          });
          if (!this.isFromPublicPage) {
            let ind = currentSlot?.indData;
            this.currentSlotData = {
              ...currentSlot,
              status_sit: currentSlot.status_sit
                ? currentSlot.status_sit
                : 'future',
              startTimeHour: this.commonFun.moment(currentSlot.startTime).format('hh:mm a'),
              endTimeHour: this.commonFun.moment(currentSlot.endTime).format('hh:mm a'),
              individual: {
                name: (ind?.firstName || '') + ' ' + (ind?.lastName || ''),
                grade: ind?.grade || '',
              },
            };
            this.isShowRescheduleSection = currentSlot?.indID ? true : false;
            await this.getPickNewTime();
          }
        } else {
          this.isShowRescheduleSection = false;
        }

        if (currentSlot.end && !this.isFromPublicPage) {
          this.selectedPreferredDate = this.commonFun.moment(currentSlot.end).format(
            'YYYY-MM-DD'
          );
          this.preferredValueChange();

          if (
            !!!this.currentSlotData?.status_appointment &&
            this.currentSlotData?.key
          ) {
            this.selectedTimeSlot = this.currentSlotData.key;
          }
        }
        if (isFromSearchIndDropDown) {
          setTimeout(() => {
            this.scrollToActiveSlot();
          }, 1000);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  getSlotColorAndStatus(slot) {
    let slotColor;
    let slotStatus;

    switch (slot.status_appointment) {
      case SlotStatus.DECLINED:
        slotColor = '#FAE2D5'; // Default Red
        slotStatus = 'DECLINED';
        break;
      case SlotStatus.CONFIRMED:
        slotColor = '#43d658'; // Light Green
        slotStatus = 'CONFIRMED';
        break;
      case SlotStatus.PROPOSED:
        slotColor = '#e59ddc'; // Light Pink
        slotStatus = 'PROPOSED';
        break;
      default:
        slotColor = '#FAE2D5'; // Default Color
        slotStatus = 'OPEN';
        break;
    }

    return { slotColor, slotStatus };
  }

  getStatusClass(status) {
    switch (status) {
      case 'complete':
        return 'completed';
      case 'noShow':
        return 'no-show';
      default:
        return 'future'; // Return an empty string if none of the cases match
    }
  }
  
  generateSlotTitle(slot, indData, slotStatus) {
    if (!slot) {
      return ''
    }
  
    return slot.type === 'lunch'
      ? 'Lunch Time'
      : `
          <div class="status-card ${this.getStatusClass(slot.status_sit)}">
            <div class="m-0 active-slot">active-slot</div>
            <h5 class="m-0">${this.commonFun.moment(slot.startTime).format('hh:mm A')}</h5>
            <p class="ind-name">${(indData?.firstName || '') + ' ' + (indData?.lastName || '')}</p>
            <h5 class="slot-status">(${slotStatus})</h5>
            </div>
            <h5 class="fs-12 mb-0 mt-1">
              ${slot?.status_sit || 'future'}
            </h5>
        `;
  }

  getHourFromTimestamp(timestamp: number): number {
    const momentObj = this.commonFun.moment(timestamp);
    return momentObj.hour();
  }

  scrollToActiveSlot() {
    const activeSlotElement =
      this.elementRef.nativeElement.querySelector('.active-slot');
    if (activeSlotElement) {
      activeSlotElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    } else {
      console.log('Active slot element not found');
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.slots = [...this.slots];
  }

  userChanged({ event, newUser }) {
    event.color = newUser.color;
    event.meta.user = newUser;
    this.slots = [...this.slots];
  }

  isEmpty(obj: any): boolean {
    return Object.values(obj).every((value) => {
      if (typeof value === 'object' && value !== null) {
        return this.isEmpty(value);
      }
      return value === '';
    });
  }

  async getPickNewTime() {
    let allSlots = await (
      await this.eventService.getAllSlots(
        this.userService.studioID,
        this.orgId,
        this.eventId, 
        this.eventCycle
      )
    ).val();
    let withoutIndIdSlotData = (
      this.commonFun.convertObjToArr(allSlots) || []
    ).filter((slot) => {
      return (
        !slot.hasOwnProperty('indID') ||
        !slot.hasOwnProperty('status_appointment') ||
        (slot.indID &&
          slot.status_appointment === SlotStatus.DECLINED &&
          slot?.type !== 'lunch')
      );
    });
    let uniqueDate = this.getUniqueDates(withoutIndIdSlotData);
    this.withoutIndIdSlotData = withoutIndIdSlotData;
    this.slotPreferredDate = uniqueDate;
  }

  preferredValueChange() {
    let selectedDate = this.slotPreferredDate.find(
      (slotDate) => slotDate.date == this.selectedPreferredDate
    );
    const dateInMillis = selectedDate?.millisecond; // Example date in milliseconds
    const result = this.getDataForDay(this.withoutIndIdSlotData, dateInMillis);
    this.slotPreferredTime = this.getUniqueDates(result, true);
  }

  getUniqueDates(
    events: any[],
    isTime = false
  ): { date: string; millisecond?: number }[] {
    const startTimes = events.map((event) => {
      let obj = {
        ...event,
        startTime: this.commonFun.moment(event.startTime),
      };
      return obj;
    });
    const uniqueDates: {
      date: string;
      millisecond?: number;
      slotId?: String;
    }[] = [];
    for (const date of startTimes) {
      const dateString = isTime
        ? date.startTime.format('hh:mm a')
        : date.startTime.format('YYYY-MM-DD');
      if (isTime) {
        uniqueDates.push({
          date: dateString,
          millisecond: date.startTime.valueOf(),
          slotId: date.key,
        });
      } else {
        const existingIndex = uniqueDates.findIndex(
          (item) => item.date === dateString
        );
        if (existingIndex == -1)
          uniqueDates.push({
            date: dateString,
            millisecond: date.startTime.valueOf(),
            slotId: date.key,
          });
      }
    }
    uniqueDates.sort((a, b) => a.millisecond - b.millisecond);
    return uniqueDates;
  }

  getDataForDay(data: any, dateInMillis: number) {
    const targetDate = this.commonFun.moment(dateInMillis);
    return data.filter(
      (item) =>
        this.commonFun.moment(item.startTime).isSame(targetDate, 'day') &&
        item.type === 'booking'
    );
  }

  async onCancelSlotBooking() {
    this.selectedPreferredDate = null;
    this.selectedTimeSlot = null;
    // await this.getCurrentSlotData()
  }

  async getCurrentSlotData() {
    let slotData = await this.eventService.getSlotByIndId(
      this.userService.studioID,
      this.orgId,
      this.eventId,
      this.currentSlotData?.indID, 
      this.eventCycle
    );

    if (!!slotData) {
      slotData?.forEach((childSnapshot: DataSnapshot) => {
        this.currentSlotData = {
          ...childSnapshot.val(),
          key: childSnapshot.key,
        };
      });
    }
  }

  async onUpdateSlot() {
    if (
      !this.currentSlotData.status_appointment ||
      !this.currentSlotData.status_sit ||
      !this.currentSlotData.indID
    ) {
      this.isShowErrMsg = true;
      return;
    } else {
      this.isShowErrMsg = false;
    }
    try {
      this.loaderService.show();
      // Check slot is available or not
      if (
        this.currentSlotData.status_appointment == SlotStatus.CONFIRMED &&
        this.selectedTimeSlot
      ) {
        // Add ind data in slot
        const currentSlotData = (
          await this.eventService.getSlotById(
            this.userService.studioID,
            this.orgId,
            this.eventId,
            this.selectedTimeSlot,
            this.eventCycle
          )
        ).val();
        if (
          currentSlotData?.indID &&
          currentSlotData?.status_appointment === SlotStatus.CONFIRMED
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'This slot is not available. Please choose another slot.',
            life: 4000,
          });
          throw new Error('Slot is already booked');
        }
      }

      // Check if 'ind' already has a slot assigned.
      if (this.currentSlotData?.indID) {
        for (const slot of this.slots) {
          if (slot.indID === this.currentSlotData.indID) {
            const slotPath = `/portal/events/${this.userService.studioID}/${this.orgId}/${this.eventCycle}/${this.eventId}/slots/${slot.key}`;
            try {
              await this.db.object(slotPath).update({
                indID: null,
                slotStartTimeString: null,
                status_appointment: null,
                status_sit: 'future',
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
      let selectedTimeSlot = this.selectedTimeSlot
        ? this.commonFun.moment(
            this.slotPreferredTime?.find(
              (slot) => slot.slotId == this.selectedTimeSlot
            ).millisecond
          ).format('h:mma z [on] MMMM D, YYYY')
        : this.commonFun.moment(this.currentSlotData.startTime).format(
            'h:mma z [on] MMMM D, YYYY'
          );
      // update slot
      this.loaderService.show('Updating slot, please wait...');
      await this.rescheduleSlot(
        this.userService.studioID,
        this.orgId,
        this.eventId,
        this.selectedTimeSlot || this.currentSlotData.key,
        this.currentSlotData?.indID,
        this.currentSlotData.status_appointment,
        selectedTimeSlot,
        this.eventCycle,
        this.currentSlotData.status_sit
      );
      // send proposed email
      if (this.currentSlotData?.status_appointment == SlotStatus.PROPOSED) {
        await this.sendEmailToProposedInds();
      }

      // update empty slots
      await this.getPickNewTime();

      this.slotPreferredTime = [];
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Slot updated successfully.',
        life: 4000,
      });
      let updatedSlotData = (
        await this.eventService.getSlotByIndId(
          this.userService.studioID,
          this.orgId,
          this.eventId,
          this.currentSlotData.indID, 
          this.eventCycle
        )
      ).val();
      let updatedSlot = updatedSlotData
        ? this.commonFun.convertObjToArr(updatedSlotData)
        : [];
      if (updatedSlot?.length > 0) {
        await this.onSlotClick(updatedSlot[0]);
        this.activeSlot = updatedSlot[0];
      }
      this.isShowErrorMassage = false;
      this.selectedPreferredDate = null;
      this.selectedTimeSlot = null;
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          error?.error?.message ||
          'Something went wrong. Please try again later.',
        life: 4000,
      });
      console.error('Error confirming cancellation status:', error);
    }
  }

  async removeIndFromSlot() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Are you sure you want to remove individual from this slot?`,
      header: "Remove Individual",
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: async () => {
        try {
          if (this.currentSlotData) {
            if (!this.userService.studioID || !this.orgId || !this.eventId || !this.currentSlotData?.key) {
              throw new Error('Parameters are missing!');
            }
            await this.eventService.removeIndFromSlot(
              this.userService.studioID,
              this.orgId,
              this.eventId,
              this.currentSlotData.key, 
              this.eventCycle
            );
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Individual successfully removed from the slot.',
              life: 4000,
            });
            this.currentSlotData = {
              ...this.currentSlotData,
              indID: null,
              slotStartTimeString: null,
              status_appointment: null,
              status_sit: null
            };
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error while removing the individual.',
            life: 4000,
          });
        }
      },
      reject: () => { },
    });
  }

  async rescheduleSlot(
    studioId,
    orgId,
    eventId,
    slotId,
    indId,
    operation,
    slotStartTimeString,
    cycle,
    sittingStatus = null
  ) {
    if (!studioId || !orgId || !eventId || !slotId) {
      throw new Error('Params missing!');
    }
    try {
      await this.eventService.updateSlotData(
        studioId,
        orgId,
        eventId,
        slotId,
        indId,
        operation,
        slotStartTimeString,
        cycle,
        sittingStatus
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  goBack() {
    let eventData = {
      ...this.eventData,
    };
    this.router.navigate(['/events'], {
      relativeTo: this.activatedRoute,
      state: { data: { eventData: { ...eventData, isFromSubject: true } } },
    });
  }

  async sendEmailToProposedInds() {
    this.loaderService.show('Sending Mail to Subjects...');
    const orgName: string =
      (await this.orgService.getOrgNameByOrgID(this.orgId)) || '';
    let sendEmailData: any = {
      from: 'noreply',
      to: [],
      recipient_variables: {},
      text: '',
      plainText: '',
      subject:
        'Please confirm your booking for (%recipient.org_displayname%) %recipient.event_displayname%',
      'v:ind_key': '%recipient.ind_key%',
      'v:org_id': this.orgId,
      'v:is_from_test': true,
      'v:is_ind_email': '%recipient.ind_email%',
      'v:is_gua_email': '%recipient.gua_email%',
      'v:event_display_name': '%recipient.event_displayname%',
      'v:slot_time': '%recipient.slot_time%',
      'v:choose_new': '%recipient.choose_new%',
      'v:confirm': '%recipient.confirm%',
      'v:decline': '%recipient.decline%',
    };

    let proposedEmailHTMLText: string = `%recipient.ind_firstname% %recipient.ind_lastname%'s proposed sitting time for the %recipient.org_displayname% event "%recipient.event_displayname%" is %recipient.slot_time%. 
        <br><br>
        Please choose a response:
        <br><br>
        Click <a href="%recipient.confirm%">here</a> to confirm that this time is acceptable
        <br><br>
        Click <a href="%recipient.choose_new%">here</a> to choose another time
        <br><br>
        Click <a href="%recipient.decline%">here</a> to decline to participate`;

    sendEmailData.text = proposedEmailHTMLText;

    let indData: Individual =
      await this.individualApiService.getIndFromFireBase(
        this.orgId,
        this.currentSlotData.indID,
        true
      );
    const indName = `${indData?.firstName || ''} ${indData?.lastName || ''}`;
    // let org: any = await this.userService.getOrgData(this.orgId);
    // const orgName = org || '';

    if (lodash.get(indData, 'email', false)) {
      sendEmailData.to.push(indData.email);
      let selectedTimeSlot = this.selectedTimeSlot
        ? this.commonFun.moment(
            this.slotPreferredTime?.find(
              (slot) => slot.slotId == this.selectedTimeSlot
            ).millisecond
          ).format('h:mma z [on] MMMM D, YYYY')
        : this.commonFun.moment(this.currentSlotData.startTime).format(
            'h:mma z [on] MMMM D, YYYY'
          );
      sendEmailData.recipient_variables[indData.email] = {
        gua_email: 0,
        ind_email: 1,
        org_displayname: orgName,
        ind_firstname: indData?.firstName,
        ind_lastname: indData?.lastName,
        ind_key: indData?._key || indData?.DBkey || null,
        slot_time: selectedTimeSlot,
        event_displayname: this.event.meta.displayName,
        choose_new: `${environment.siteUrl}generic/slot-scheduling?orgId=${
          this.orgId
        }&indId=${indData?._key || indData?.DBkey}&eventId=${
          this.eventId
        }&studioId=${this.userService.studioID}&cycle=${this.eventCycle}&slotId=${
          this.selectedTimeSlot || this.currentSlotData.key
        }&orgName=${orgName}&indName=${indName}&grade=${
          indData?.grade || ''
        }&studentID=${indData?.studentID || ''}`,
        confirm: `${environment.siteUrl}generic/slot-scheduling?orgId=${
          this.orgId
        }&indId=${indData?._key || indData?.DBkey}&eventId=${
          this.eventId
        }&studioId=${this.userService.studioID}&cycle=${this.eventCycle}&slotId=${
          this.selectedTimeSlot || this.currentSlotData.key
        }&orgName=${orgName}&indName=${indName}&grade=${
          indData.grade
        }&studentID=${indData.studentID}&isConfirm=true`,
        decline: `${environment.siteUrl}generic/slot-scheduling?orgId=${
          this.orgId
        }&indId=${indData?._key || indData?.DBkey}&eventId=${
          this.eventId
        }&studioId=${this.userService.studioID}&cycle=${this.eventCycle}&slotId=${
          this.selectedTimeSlot || this.currentSlotData.key
        }&orgName=${orgName}&indName=${indName}&grade=${
          indData.grade
        }&studentID=${indData.studentID}&isConfirm=false`,
      };
    }

    try {
      await this.sendEmail(sendEmailData);
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      console.error('Error occurred:', error);
    }
  }

  // --- send normal email or html email
  async sendEmail(sendEmailData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let currentCycle: any;
      this.commonFun
        .getReportingYear(this.userService.studioID)
        .then((cycle: any) => {
          currentCycle = cycle;
          const obj = {
            isFromTest: !environment.production,
            orgId: this.orgId,
            currentCycle: currentCycle,
            data: [sendEmailData],
            type: 'single',
            emailType: MsgMedium.EMAIL_HTML,
          };
          this.api.post(environment.mailgunObj.endPoint, obj).subscribe({
            next: (result: any) => {
              if (result.status == 'ok') {
                this.loaderService.hide();
                resolve();
              } else {
                this.loaderService.hide();
                reject(new Error('Failed to send email.'));
              }
            },
            error: (error: any) => {
              console.log('error: ', error);
              this.loaderService.hide();
              reject(error);
            },
          });
        })
        .catch((error) => {
          console.log('error: ', error);
          this.loaderService.hide();
          reject(error);
        });
    });
  }

  async showSlot(slotObj) {
    if (!slotObj) return;
    this.viewDate = this.commonFun.convertTimestampToOrgTimezoneDate(moment(slotObj.startTime).startOf('day').valueOf())
    
    this.setNextPrevBtn();
    this.activeSlot = slotObj;
    await this.onSlotClick(slotObj, true);
  }

  showAddEditIndModelDialog() {
    this.showAddEditIndModel = true;
  }
  async closeAddEditIndDialog($event: any) {
    this.showAddEditIndModel = false;
    if (!$event) return;

    try {
      this.loaderService.show();
      this.currentSlotData.status_appointment = SlotStatus.PROPOSED;
      this.currentSlotData.status_sit = 'future';
      this.currentSlotData.indID = $event.DBkey;

      const path = `portal/events/${this.userService.studioID}/${this.orgId}/${this.eventCycle}/${this.eventId}/subjects`;

      if (this.eventData?.event?.subjects) {
        this.eventData.event.subjects.push($event.DBkey);
        await this.db.object(path).update(this.eventData.event.subjects);
        // await this.getAllIndByOrgId();
        await this.onUpdateSlot();
      }
    } catch (error) {
      this.loaderService.hide();
      console.error(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          this.commonFun.prepareErrorMessage(error) ||
          'Something went wrong, Please try again later.',
        life: 4000,
      });
    } finally {
      this.loaderService.hide();
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscriber) {
      this.eventSubscriber.unsubscribe();
    }
  }
}
