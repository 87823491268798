import { Component, OnInit} from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { CommonServiceService } from '../../../shared/services/common-service.service';
import { Router } from '@angular/router';
import { EditSchoolInfoComponent } from '../../../shared/modals/edit-school-info/edit-school-info.component';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PasswordModule } from 'primeng/password';
import { OrgType, SourceValue } from '../../../shared/constants/enums';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import lodash from 'lodash';
import { UserService } from '../../../shared/services/user/user.service';
import { IndividualService } from '../../../shared/services/individuals/individual.service';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { TeammateService } from '../../../shared/services/teammateService/teammate.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Utils } from '../../../shared/utils/utils';
import { FormArray} from '@angular/forms';
import { ApiHelperService, CloudFnNames } from '../../../shared/services/apiHelperService/api-helper.service';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-edit-school',
  standalone: true,
  imports: [
    SharedModule,
    EditSchoolInfoComponent,
    PasswordModule,
    InputSwitchModule,
    RadioButtonModule,
    CheckboxModule
  ],
  templateUrl: './edit-school.component.html',
  styleUrl: './edit-school.component.scss',
})
export class EditSchoolComponent implements OnInit {
  contactData;
  schoolData: any;
  contactList: any[] = [];
  representativeList: any[] = [];
  isLoading: boolean = false;
  imageFile: File;
  logoPreview: any;
  isLogoDeleted: boolean = false;
  countries: any[] = [];
  isBtnDisable: boolean = false;
  repList: any[] = [];
  fileDialog: boolean = false;
  schoolBasicForm: FormGroup;
  activeIndex = 0;
  activeSubIndex = 0;
  passwordForm: FormGroup;
  schoolTechnicalForm: FormGroup;
  high5Fields: any[] = [];
  sources: any[] = [
    { name: 'OneRoster', value: SourceValue.OneRoster },
    { name: 'CSV', value: SourceValue.CSV },
    { name: 'Aeries', value: SourceValue.Aeries },
  ];
  SourceValue = SourceValue;
  isDisabledSyncSource: boolean = false;
  selectedSource: string = '';
  districtList: any[] = [];
  isSetPw: boolean = false;
  compareSchoolBasicData: any;
  compareSchoolTecData: any;
  isEdit: boolean = false;
  selectedVisible: any;
  isInvalidMapping: boolean = false;
  items: any[] = [
    {
      label: 'Edit',
      icon: '../../../../assets/svg/edit-icon.svg',
      command: () => {
        this.selectedOption('edit');
      },
    },
    {
      label: 'Send Password Reset Link',
      icon: '../../../../assets/svg/message.svg',
      command: () => {
        this.selectedOption('resetPassword');
      },
    },
    {
      label: 'Delete',
      icon: '../../../../assets/svg/trash.svg',
      command: () => {
        this.selectedOption('delete');
      },
    },
  ];
  orgVisible: any[] = [
    {
      id: true,
      name: 'Yes',
    },
    {
      id: false,
      name: 'No',
    },
  ];
  role = new FormControl('', [Validators.required]);
  public lodash =lodash;
  adminIndData: any;
  timeListByCountry: any[] = [];
  oneRosterKeys: { [key: string]: string } = {};
  oneRosterAccessToken: any;
  oneRosterKeyList: any[]= [];
  individualList: any;
  homeRoomStrategyList: any[] = [
    {label: 'Class period contains specific text' , value: 'classPeriodContainSpecificText'},
    {label: ' Class title contains specific text' , value: 'classTitleContainSpecificText'},
    {label: 'Most common teacher' , value: 'mostCommonTeacher'},
    {label: 'Class Type is "homeroom"' , value: 'classTypeHomeroom'},
  ]
  showAllClassTitles: boolean = false;
  oneRosterFieldCopyAsHomeroom = [
    {label: 'classCode', value: 'classCode'},
    {label: 'title', value: 'title'},
  ]
  constructor(
    private commonFun: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private db: AngularFireDatabase,
    private loaderService: LoaderService,
    private messageService: MessageService,
    public user: UserService,
    private storage: AngularFireStorage,
    private confirmationService: ConfirmationService,
    private individual: IndividualService,
    private orgService: OrganizationService,
    private teamMateService: TeammateService,
    private authService: AuthService,
    private utils: Utils,
    private individualService: IndividualService,
    private apiHelperService: ApiHelperService,
  ) {
    let navigationState = this.router.getCurrentNavigation()?.extras?.state;
    if (navigationState) {
      this.schoolData = navigationState['schoolData'];
      this.districtList = navigationState['distList'];
      if(!!this.schoolData.activeTab) this.activeIndex = this.schoolData.activeTab;
    } else return;
  }
  comparePageData: any;
  pageData: any;
  homeRoomRes: any;
  totalStaffList: any[] = [];
  totalStudentList: any[] = [];
  totalGuardianList: any[] = [];
  oneRosterStaffHomeroomsList: any[] = [];
  oneRosterStudentHomeroomsList: any[] = [];
  oneRosterGuardianHomeroomsList: any[] = [];
  oneRosterStaffHomeroomsPer: any;
  oneRosterStudentHomeroomsPer: any;
  oneRosterGuardianHomeroomsPer: any;
  uniqSampleHomeroomList: any[];
  isOneRosterConfigInvalid: boolean = false;
  uniqClassList: any[] = [];
  classList: any[] = [];
  roleMappedBackupData: any[] = [];
  onValueChange(type?: string) {
    switch (this.activeIndex) {
      case 0:
        this.pageData = this.schoolBasicForm.value;
        this.comparePageData = this.compareSchoolBasicData;
        this.commonFun.goBackButton$.next({
          pageTitle: 'Schools',
          isShow: true,
          comparePageData: this.compareSchoolBasicData,
          pageData: this.schoolBasicForm.value,
        });
        break;

      case 1:
        this.pageData = {};
        this.comparePageData = {};
        this.commonFun.goBackButton$.next({
          pageTitle: 'Schools',
          isShow: true,
        });
        break;

      case 2:
        this.pageData = this.schoolTechnicalForm.value;
        this.comparePageData = this.compareSchoolTecData;
        this.commonFun.goBackButton$.next({
          pageTitle: 'Schools',
          isShow: true,
          comparePageData: this.compareSchoolTecData,
          pageData: this.schoolTechnicalForm.value,
        });
        const {syncTokenUrl,
          clientID,
          sourceId,
          clientSecret,
          syncBaseUrl} = this.schoolTechnicalForm.controls;
        this.isOneRosterConfigInvalid = lodash.some([
          syncTokenUrl.value,
          clientID.value,
          sourceId.value,
          clientSecret.value,
          syncBaseUrl.value], (value) => !!!value)
        break;
    }

    if (
      this.homeRoomRes &&
      !!(
        this.homeRoomRes.result?.oneRosterclassTitleContainSpecificTextInds?.length ||
        this.homeRoomRes.result?.oneRosterclassPeriodContainSpecificTextInds?.length ||
        this.homeRoomRes.result?.oneRosterCommonTeacherClassInds?.length ||
        this.homeRoomRes.result?.oneRosterHomeRoomclassInds?.length
      )
    ) {
      let oneRosterclassTitleContainSpecificTextInds = this.homeRoomRes.result?.oneRosterclassTitleContainSpecificTextInds || [];
      let oneRosterclassPeriodContainSpecificTextInds = this.homeRoomRes.result?.oneRosterclassPeriodContainSpecificTextInds || [];
      let oneRosterClassRes = this.homeRoomRes.result.oneRosterHomeRoomclassInds || [];
      let oneRosterCommonTeacherClassRes = this.homeRoomRes.result.oneRosterCommonTeacherClassInds || [];
      let homeroomClassSelected = this.schoolTechnicalForm.value.homeroomMapFrom || '';
      let homeroomStrategySelected = this.schoolTechnicalForm.value.homeroomStrategy || '';

      if (homeroomStrategySelected === 'classTitleContainSpecificText') {
        if(type == 'homeroomStrategy') {
          this.schoolTechnicalForm.controls['textIncludeInHomeroom'].setValue(lodash.get(this.schoolData?.oneRosterHomeroomMapping, 'titleTextIncludeInHomeroom', null));
          if(lodash.isNil(this.schoolTechnicalForm.value.textIncludeInHomeroom) || lodash.isEmpty(this.schoolTechnicalForm.value.textIncludeInHomeroom)) {
            this.schoolTechnicalForm.controls['textIncludeInHomeroom'].setValue('Advisory; Engagement; Homeroom');
          }
        }
        this.oneRosterStaffHomeroomsList = [];
        this.oneRosterStudentHomeroomsList = [];
        this.oneRosterGuardianHomeroomsList = [];
        this.uniqClassList = [];
        this.classList = [];
        this.roleMappedBackupData = [];

        let roleMappedOneRosterAdvisoryRes = lodash.map(oneRosterclassTitleContainSpecificTextInds, (obj) => {
          let temp = obj;
          temp.classTitleContainSpecificTextClass.role = obj.role ? obj.role : obj.roles[0].role;
          temp.classTitleContainSpecificTextClass.sourcedId = obj?.sourcedId
          return temp;
        });

        this.roleMappedBackupData.push(
          ...lodash
            .filter(roleMappedOneRosterAdvisoryRes, classObj =>
              lodash.get(classObj, 'classTitleContainSpecificTextClass.title', false)
            )
            .map(classObj => lodash.get(classObj, 'classTitleContainSpecificTextClass'))
        );
        this.uniqClassList = this.uniqList(this.roleMappedBackupData, 'title');
        this.searchHomeroom();
        this.prepareHomeroomCounting();
      } else if(homeroomStrategySelected == 'classPeriodContainSpecificText') {
        if(type == 'homeroomStrategy') {
          this.schoolTechnicalForm.controls['textIncludeInHomeroom'].setValue(lodash.get(this.schoolData?.oneRosterHomeroomMapping, 'periodTextIncludeInHomeroom', null));
          if(lodash.isNil(this.schoolTechnicalForm.value.textIncludeInHomeroom) || lodash.isEmpty(this.schoolTechnicalForm.value.textIncludeInHomeroom)) {
            this.schoolTechnicalForm.controls['textIncludeInHomeroom'].setValue('M-P1');
          }
        }
        this.oneRosterStaffHomeroomsList = [];
        this.oneRosterStudentHomeroomsList = [];
        this.oneRosterGuardianHomeroomsList = [];
        this.uniqClassList = [];
        this.classList = [];
        this.roleMappedBackupData = [];

        let roleMappedOneRosterAdvisoryRes = lodash.map(oneRosterclassPeriodContainSpecificTextInds, (obj) => {
          let temp = obj;
          temp.classPeriodContainSpecificTextClass.role = obj.role ? obj.role : obj.roles[0].role;
          temp.classPeriodContainSpecificTextClass.sourcedId = obj?.sourcedId
          return temp;
        });

        this.roleMappedBackupData.push(
          ...lodash
            .filter(roleMappedOneRosterAdvisoryRes, classObj =>
              lodash.get(classObj, 'classPeriodContainSpecificTextClass.periods', false)
            )
            .map(classObj => lodash.get(classObj, 'classPeriodContainSpecificTextClass'))
        );

        const periodList = lodash.chain(roleMappedOneRosterAdvisoryRes).map(classObj => lodash.get(classObj, 'classPeriodContainSpecificTextClass.periods', [])).filter(Array.isArray).flatten().value();
        this.uniqClassList = this.uniqList(periodList);
        this.searchHomeroom();
        this.prepareHomeroomCounting();
      } else if (homeroomStrategySelected == 'classTypeHomeroom') {
        this.oneRosterStaffHomeroomsList = [];
        this.oneRosterStudentHomeroomsList = [];
        this.oneRosterGuardianHomeroomsList = [];
        this.classList = [];

        let roleMappedOneRosterClassRes = lodash.map(oneRosterClassRes, (obj) => {
          let temp = obj;
          temp.oneRosterHomeRoomclass.role = obj.role ? obj.role : obj.roles[0].role;
          temp.oneRosterHomeRoomclass.sourcedId = obj?.sourcedId
          return temp;
        });

        let [student, staff, guardian]: any[] = this.getRoleWiseList(roleMappedOneRosterClassRes);
        this.oneRosterStudentHomeroomsList = student;
        this.oneRosterStaffHomeroomsList = staff;
        this.oneRosterGuardianHomeroomsList = guardian;
        this.classList.push(
          ...lodash
            .filter(roleMappedOneRosterClassRes, classObj =>
              lodash.get(classObj, 'oneRosterHomeRoomclass.title', false)
            )
            .map(classObj => lodash.get(classObj, 'oneRosterHomeRoomclass'))
        );

        this.prepareHomeroomCounting();
        this.getSampleHomeRoomData();
      } else if(homeroomStrategySelected == 'mostCommonTeacher') {
        this.oneRosterStaffHomeroomsList = [];
        this.oneRosterStudentHomeroomsList = [];
        this.oneRosterGuardianHomeroomsList = [];
        this.classList = [];

        let roleMappedOneRosterCommonTeacherClassRes = lodash.map(oneRosterCommonTeacherClassRes, (obj) => {
          let temp = obj;
          temp.oneRosterMostCommonTeacherclass.role = obj.role ? obj.role : obj.roles[0].role;
          temp.oneRosterMostCommonTeacherclass.sourcedId = obj?.sourcedId
          return temp;
        });

        let [student, staff, guardian]: any[] = this.getRoleWiseList(roleMappedOneRosterCommonTeacherClassRes);
        this.oneRosterStudentHomeroomsList = student;
        this.oneRosterStaffHomeroomsList = staff;
        this.oneRosterGuardianHomeroomsList = guardian;
        this.classList.push(
          ...lodash
            .filter(roleMappedOneRosterCommonTeacherClassRes, classObj =>
              lodash.get(classObj, 'oneRosterMostCommonTeacherclass.title', false)
            )
            .map(classObj => lodash.get(classObj, 'oneRosterMostCommonTeacherclass'))
        );
        this.prepareHomeroomCounting();
        this.getSampleHomeRoomData();
      } else {
        this.oneRosterStaffHomeroomsList = [];
        this.oneRosterStudentHomeroomsList = [];
        this.oneRosterGuardianHomeroomsList = [];
        this.classList = [];
        this.oneRosterStaffHomeroomsPer = 0;
        this.oneRosterStudentHomeroomsPer = 0;
        this.oneRosterGuardianHomeroomsPer = 0;
        this.getSampleHomeRoomData();
      }
    }
  }

  /**
   * @param list Provide a list of all data
   * @param uniqBy uniq data key
   * @returns uniq data list
   */
  uniqList(list: any[], uniqBy: string = "") {
    if (lodash.isEmpty(uniqBy))
      return lodash.uniq(list);
    else
      return lodash.uniqBy(list, uniqBy);
  }

  /**
   * Search Homeroom data
   */
  searchHomeroom = lodash.debounce(async () => {
    const homeroomInput = this.schoolTechnicalForm.controls['textIncludeInHomeroom'].value;
    let homeroomStrategySelected = this.schoolTechnicalForm.value.homeroomStrategy || '';

    if (lodash.isEmpty(homeroomInput)) {
      this.oneRosterStaffHomeroomsList = [];
      this.oneRosterStudentHomeroomsList = [];
      this.oneRosterGuardianHomeroomsList = [];
      return;
    }

    let splitSearchValue = homeroomInput.split(';')

    let searchFilterData: any[] = [];
    splitSearchValue.forEach((searchTerm: string) => {
      let searchTxt = searchTerm.trim();
      if (lodash.isEmpty(searchTxt.trim())) return;

      if (homeroomStrategySelected === 'classTitleContainSpecificText') {
        let filteredClasses = this.roleMappedBackupData.filter((classObj: any) => lodash.get(classObj, 'title', '').toLowerCase().includes(searchTxt.toLowerCase()));
        searchFilterData.push(...filteredClasses);
      } else if(homeroomStrategySelected == 'classPeriodContainSpecificText') {
        let filteredClasses = this.roleMappedBackupData.filter((classObj: any) =>
          lodash.get(classObj, 'periods', '').toString().toLowerCase().includes(searchTxt.toLowerCase())
        );
        searchFilterData.push(...filteredClasses);
      }
    });
    this.oneRosterStaffHomeroomsList = [];
    this.oneRosterStudentHomeroomsList = [];
    this.oneRosterGuardianHomeroomsList = [];
    let uniqFilteredDta = this.uniqList(searchFilterData, 'sourcedId');
    let [student, staff, guardian]: any[] = this.getRoleWiseList(uniqFilteredDta);
    this.oneRosterStudentHomeroomsList = student
    this.oneRosterStaffHomeroomsList = staff
    this.oneRosterGuardianHomeroomsList = guardian
    this.classList = [];
    this.classList = [...student, ...staff, ...guardian];

    this.prepareHomeroomCounting();
    this.getSampleHomeRoomData();

  }, 500);


  /**
   * Show the sample homeroom class/title data
   */
  getSampleHomeRoomData() {
    let sampleHomeroomList: any[] = []
    let homeroomClassSelected = this.schoolTechnicalForm.value.homeroomMapFrom || '';
    lodash.forEach(this.classList, (classObj) => {
      if (homeroomClassSelected in classObj) {
        sampleHomeroomList.push(classObj[homeroomClassSelected]);
      }
    })
    this.uniqSampleHomeroomList = this.uniqList(sampleHomeroomList);
  }

  /**
   * Prepare homeroom count data
   */
  prepareHomeroomCounting() {
    this.oneRosterStaffHomeroomsPer = Math.round((this.oneRosterStaffHomeroomsList.length * 100) / this.totalStaffList.length);
    this.oneRosterStudentHomeroomsPer = Math.round((this.oneRosterStudentHomeroomsList.length * 100) / this.totalStudentList.length);
    this.oneRosterGuardianHomeroomsPer = Math.round((this.oneRosterGuardianHomeroomsList.length * 100) / this.totalGuardianList.length);
  }

  /**
   * @param list Provide a list of all data
   * @returns Role base data
   */
  getRoleWiseList(list: any[] = []) {
    let studentList: any[] = [];
    let staffList: any[] = [];
    let guaList: any[] = [];

    lodash.forEach(list, (obj) => {
      let indRole = obj.role ? obj.role : obj.roles[0].role
      if(indRole.toLowerCase() == 'student') {
        studentList.push(obj);
      } else if(indRole.toLowerCase() == 'staff') {
        staffList.push(obj)
      } else if(indRole.toLowerCase() == 'guardian') {
        guaList.push(obj);
      }
    })

    return [studentList, staffList, guaList]
  }

  async ngOnInit() {
    let toBeRemoveFields = [
      "accessCampus_mode",
      "accessBus_mode",
      "accessSocial_mode",
      "accessAthletics_mode",
      "idValid_mode",
      "permissionToLeave_mode",
      "permissionFlexSchedule_mode",
      "lastName",
      "firstName",
      "indID_Client",
      "photoStatus",
      "role",
      "status",
      "teammatePURL",
    ]
    this.high5Fields = [{ name: 'Not Mapped', property: 'notMapped', synonyms: [], title: '-not mapped-' }, ...this.individualService.getHigh5Fields(OrgType.SCHOOL, toBeRemoveFields)];
    this.timeListByCountry = this.utils.timezoneList;
    this.initializeForm();
    if(this.schoolData && this.schoolData.oneRosterMappingKeys) {
      this.schoolData.oneRosterMappingKeys.map((item) => {
        this.oneRosterKeyList.push({ oneRosterField: item });
      });
      this.addKeyMappingControls();
    }

    this.districtList = lodash.filter(
      this.districtList,
      (district: any) => !lodash.isEmpty(district.key)
    );
    this.repList = [];
    if (this.schoolData) {
      if (this.schoolData?.orgLogo?.includes('https'))
        this.logoPreview = this.schoolData.orgLogo;
    }

    // Show back Button
    this.commonFun.goBackButton$.next({ pageTitle: 'Schools', isShow: true });

    this.loaderService.show();
    try {
      if (!!this.schoolData?.key) {
        let schoolAuthId: string = (
          await this.orgService.getFirebaseIdOfOrg(this.schoolData?.key)
        ).val();
        let adminIndObj = await this.individual.getIndividualDataUsingUid(
          this.schoolData.key,
          schoolAuthId
        );

        if (!lodash.isEmpty(adminIndObj)) {
          let adminIndData = this.commonFun.convertObjToArr(adminIndObj)[0];
          let obj = {
            firstName: adminIndData.firstName || '-',
            lastName: adminIndData.lastName || '-',
            phone: adminIndData.mobilePhone || '-',
            email: adminIndData.email || '-',
            access: 'Admin',
            title: adminIndData.title || '-',
            isMainAdmin: adminIndData.isMainAdmin || false,
          };
          this.contactList.push(obj);
          this.adminIndData = adminIndData;
        }
        await this.getContact();
        await this.getRepresentative(this.schoolData);
      }
      this.patchVal();
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      console.error("Error occurred:", error);
    } finally {
      this.loaderService.hide();
    }
  }

  isNeedToTest: boolean = false;
  onValidCredential() {
    let schoolObj = {
      syncBaseUrl: this.schoolData?.syncBaseUrl,
      syncTokenUrl: this.schoolData?.syncTokenUrl,
      clientSecret: this.schoolData?.clientSecret,
      clientID: this.schoolData?.clientID,
      sourceId: this.schoolData?.sourceId
    }
    let schoolTempObj = {
      syncBaseUrl: this.schoolTechnicalForm.value.syncBaseUrl,
      syncTokenUrl: this.schoolTechnicalForm.value.syncTokenUrl,
      clientSecret: this.schoolTechnicalForm.value.clientSecret,
      clientID: this.schoolTechnicalForm.value.clientID,
      sourceId: this.schoolTechnicalForm.value.sourceId
    }
    if(JSON.stringify(schoolObj) != JSON.stringify(schoolTempObj)) {
      this.isNeedToTest = true;
      this.homeRoomRes = [];
    } else {
      this.isNeedToTest = false;
    }
  }


  updateFormValidator() {
    const {sourceId, clientSecret, clientID, syncBaseUrl, syncTokenUrl, certificate} = this.schoolTechnicalForm.controls;

    if (this.schoolTechnicalForm.value.syncSource == SourceValue.OneRoster) {
      sourceId.setValidators([Validators.required]);
      clientSecret.setValidators([Validators.required]);
      clientID.setValidators([Validators.required]);
      syncBaseUrl.setValidators([Validators.required]);
      syncTokenUrl.setValidators([Validators.required]);

      // Remove validator
      certificate.clearValidators();

    } else if (this.schoolTechnicalForm.value.syncSource == SourceValue.Aeries) {
      sourceId.setValidators([Validators.required]);
      certificate.setValidators([Validators.required]);
      syncBaseUrl.setValidators([Validators.required]);

      // Remove validator
      clientSecret.clearValidators();
      clientID.clearValidators();
      syncTokenUrl.clearValidators();

    } else if (this.schoolTechnicalForm.value.syncSource == SourceValue.CSV) {
      // Remove validator
      sourceId.clearValidators();
      syncBaseUrl.clearValidators();
      certificate.clearValidators();
      clientID.clearValidators();
      clientSecret.clearValidators();
      syncTokenUrl.clearValidators();
    }

    [
      sourceId,
      clientSecret,
      clientID,
      syncBaseUrl,
      syncTokenUrl,
      certificate
    ].forEach((control) => {
      control.updateValueAndValidity();
    });
  }

  filteredActionList: any[] = [];
  filterActionButton(contact: any){
    this.filteredActionList = this.items.filter((item)=>{
      if(!!contact.isMainAdmin){
        return item.label == 'Send Password Reset Link';
      }else{
        return item.label != 'Send Password Reset Link';
      }
    })
  }

  togglePasswordVisibility(passwordInput: any) {
    passwordInput.inputType =
      passwordInput.inputType === 'password' ? 'text' : ('password' as string);
  }

  initializeForm() {
    this.schoolBasicForm = this.fb.group({
      orgName: ['', Validators.required],
      shippingState: ['', Validators.required],
      email: [''],
      phone: ['', Validators.required],
      shippingCity: ['', Validators.required],
      shippingAddress: ['', Validators.required],
      shippingPostCode: ['', Validators.required],
      administratorRepId: ['', Validators.required],
      administratorFirstName: ['', Validators.required],
      administratorLastName: ['', Validators.required],
      districtID: [''],
      timezone: ['', Validators.required]
    });
    this.schoolTechnicalForm = this.fb.group({
      syncSource: [''],
      sourceId: [''],
      clientSecret: [''],
      clientID: [''],
      syncBaseUrl: [''],
      certificate: [''],
      syncTokenUrl: [''],
      isNeedToSyncClass: [false],
      individual: [''],
      mappingData: this.fb.array([]),
      homeroomStrategy: [''],
      homeroomMapFrom: [''],
      textIncludeInHomeroom: [''],
    });

    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [
        '',
        [Validators.required, this.passwordMatchValidator.bind(this)],
      ],
    });
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const passwordControl = control.root.get('password');
    const confirmPasswordControl = control.root.get('confirmPassword');

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }
    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    if (password !== confirmPassword) {
      confirmPasswordControl.setErrors({ passwordsNotMatch: true });
      return { passwordsNotMatch: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  }

  selectedIndex: number = null;
  async selectedOption(menu: string) {
    if (menu === 'delete') {
      this.deleteContact(this.contactList[this.selectedIndex], this.selectedIndex)
    } else if (menu === 'resetPassword') {
      this.sendPWResetLink(this.contactList[this.selectedIndex]);
    } else if (menu === 'edit') {
      this.openAddEditRepresentativeDialog(this.contactList[this.selectedIndex]);
    } else {
      console.log(menu + " is under development.");
    }
  }

  openAddEditRepresentativeDialog(representative?: any) {
    this.isEdit = representative ? true : false;
    this.fileDialog = true;
    this.contactData = { ...representative, isEdit: this.isEdit };
  }

    deleteContact(contact: any, i: number) {
      this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Are you sure you want to delete this contact?`,
      header: 'Delete Contact',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: async () => {
        await this.orgService
          .removeContactFromOrg(this.schoolData.key, contact.key)
          .then(() => {
            this.contactList.splice(i, 1);
            if (contact?.key && contact?.settings?.visuals?.contactImage) {
              const filePath = `/photos/contacts/${contact?.key}/contactImage`;
              const storageRef = this.storage.ref(filePath);

              storageRef.delete().subscribe({
                next: () => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Representative deleted successfully',
                  });
                },
                error: (err) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong. Please try again later.',
                  });
                  throw err;
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
    });
  }

    // --- send password reset link
    async sendPWResetLink(contact) {
      let email = contact.meta.email;
      if(!email || !this.commonFun.isEmailValid(email)) {
        this.openConfirmationDialog(
          'Attention',
          'We do not have a valid email to send password reset link!',
          'Cancel',
          null,
          false,
          true,
          false,
        );
        return;
      }

      // --- ask for confirmation
      this.openConfirmationDialog(
        'Alert',
        `Would you like to send a password reset link to <b>${email}</b>?`,
        'Yes',
        'No',
        false,
        true,
        true,
        async () => {
          await this.loaderService.show();

          let [, errSendingPWResetLink] = await this.commonFun.executePromise(
            this.authService.sendPWResetEmail(email)
          );

          await this.loaderService.hide();

          if (errSendingPWResetLink) {
            this.commonFun.handleError(errSendingPWResetLink);
            return;
          }

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Password reset link sent successfully to the email ${email}`,
          });
        },
        () => {
          return;
        }
      );
    }
  async getContact() {
    this.isLoading = true;
    this.contactList = [];
    try {
      const schoolAuthId = (
        await this.orgService.getFirebaseIdOfOrg(this.schoolData.key)
      ).val();
      const adminIndObj = await this.individual.getIndividualDataUsingUid(
        this.schoolData.key,
        schoolAuthId
      );

      if (!lodash.isEmpty(adminIndObj)) {
        let adminIndData: any = Object.values(adminIndObj)[0];
        const contactObject = {
          isMainAdmin: adminIndData.isMainAdmin || false,
          meta: {
            firstName: adminIndData.firstName || '-',
            lastName: adminIndData.lastName || '-',
            phone: adminIndData.mobilePhone || '-',
            email: adminIndData.email || '-',
            access: 'Admin',
            title: adminIndData.title || '-',
          },
        };
        this.contactList.push(contactObject);
      }

      const contact = await this.orgService.getContactFromOrg(
        this.schoolData.key
      );
      const tempContactList = contact.val()
        ? this.commonFun.convertObjToArr(contact.val())
        : [];

      if (tempContactList.length > 0) {
        this.contactList.push(...tempContactList);
      }
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      console.error(err);
    }
  }

  async getRepresentative(schoolData: any) {
    this.representativeList =  await this.teamMateService.getRepresentative(schoolData)
    this.selectedRep = this.representativeList.find((rep) => !!lodash.get(rep,'isPrimaryContact' , false )) || null;
    if(this.selectedRep){
      this.schoolBasicForm.controls['administratorRepId'].setValue(this.selectedRep.key);
    }
  }

  async onChangeCheckBoxVal(representative: any) {
    this.loaderService.show();
    try {
      this.selectedRep = representative.isPrimaryContact ?? representative;
      let orgStudioRepObj = {
        permissions: {
          isPrimaryContact: representative.isPrimaryContact,
          isVisibleToSchool: representative.isVisibleToSchool,
        },
        role: representative.role,
      };

      let orgRep = (await this.db.object(`/organizations/${this.schoolData.key}/studioRepresentatives/`).query.once('value')).val();
     if(orgRep) {
      orgRep = this.commonFun.convertObjToArr(orgRep);
        orgRep = orgRep.map((rep) => {
          if(representative.isPrimaryContact && rep.key != representative.key){
            rep.permissions.isPrimaryContact =  false;
          }else if(rep.key == representative.key) {
            rep = {...orgStudioRepObj, key: rep.key};
          }
          return rep;
        })}
      let isRepAwailableInOrg = orgRep && orgRep.find((rep) =>rep.key == representative.key) ||null;
      if(!!!isRepAwailableInOrg){
        orgRep = orgRep ? [...orgRep, {...orgStudioRepObj, key: representative.key}] : [{...orgStudioRepObj, key: representative.key}];
      }
      orgRep = this.commonFun.convertArrToObj(orgRep);

      await this.db
        .object(`/organizations/${this.schoolData.key}/studioRepresentatives/`)
        .update(orgRep);
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      console.error('Error:', error);
    }
    await this.getRepresentative(this.schoolData);
  }

  selectedRep:any;
  async updateVisible(representative?, refreshList: boolean = false) {
    if(!!representative.data) representative = representative.data;
    if(!!!representative && !!this.selectedRep) representative = this.selectedRep;

    let orgStudioRepObj = {
      permissions: {
        isPrimaryContact: this.selectedRep && representative ? representative.key == this.selectedRep.key : false,
        isVisibleToSchool: representative.isVisibleToSchool,
      },
      role: representative.role,
    };

    this.addUpdateStudioRepInOrg(representative.key, orgStudioRepObj);
    if (refreshList) {
      await this.getRepresentative(this.schoolData)
    }
  }

  addUpdateStudioRep(contactId, updateObj) {
    if (this.schoolData.studioID && contactId) {
      return this.db
        .object(
          `portal/studioRep/${this.schoolData.studioID}/${contactId}`
        )
        .update(updateObj);
    } else {
      return this.db.list(`portal/studioRep/${this.schoolData.studioID}`).push(updateObj);
    }
  }

  addUpdateStudioRepInOrg(contactId, updateObj) {
    if (this.schoolData.studioID && contactId) {
      return this.db
        .object(
          `/organizations/${this.schoolData.key}/studioRepresentatives/${contactId}`
        )
        .update(updateObj);
    } else {
      return this.db.list(`/organizations/${this.schoolData.key}/studioRepresentatives/`).push(updateObj);
    }
  }

  onFileSelected(event: any) {
    let files = event.target.files || event.dataTransfer.files;
    this.imageFile = files[0];
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.logoPreview = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  removeLogo() {
    this.logoPreview = null;
    this.imageFile = null;
    this.isLogoDeleted = true;
  }

  onTabChange(event: any) {
    this.activeIndex = event.index;
  }

  closeModel(event: any) {
    this.isSetPw = false;
    this.isEdit = false;
    if (event?.refreshList) {
      this.getContact();
    }
    this.fileDialog = false;
  }
  isSchoolTechnicalFormValid: boolean = false;
  handleSchoolTechnicalFormValidation() {
    if (this.schoolTechnicalForm.value.syncSource == SourceValue.OneRoster) {
      const {
        syncTokenUrl,
        clientID,
        sourceId,
        clientSecret,
        syncBaseUrl,
        homeroomStrategy,
        homeroomMapFrom,
      } = this.schoolTechnicalForm.controls;

      [syncBaseUrl, syncTokenUrl, clientID, clientSecret, sourceId].forEach(
        (control: any) => {
          control && control.setValidators([Validators.required]);
          control && control.markAllAsTouched();
          control && control.updateValueAndValidity();
          return control;
        }
      );
      let schoolOneRosterConfigFormHasValue = lodash.some(
        [
          syncBaseUrl.value,
          syncTokenUrl.value,
          clientID.value,
          clientSecret.value,
          sourceId.value,
        ],
        (value) => !!value
      );

      if (schoolOneRosterConfigFormHasValue) {
        let isSchoolOneRosterConfigFormInvalid = lodash.some(
          [
            syncBaseUrl.invalid,
            syncTokenUrl.invalid,
            clientID.invalid,
            clientSecret.invalid,
            sourceId.invalid,
          ],
          (invalid) => invalid
        );

        if (!isSchoolOneRosterConfigFormInvalid) {
          if (
            !!(
              this.individualList?.length ||
              this.schoolTechnicalForm.value.isNeedToSyncClass ||
              homeroomMapFrom.value ||
              homeroomStrategy.value
            )
          ) {
            this.checkValidation();
            if (!this.isInvalidMapping) {
              if (!!this.schoolTechnicalForm.value.isNeedToSyncClass) {
                [homeroomMapFrom, homeroomStrategy].forEach((control) => {
                  control.setValidators([Validators.required]);
                  control.markAsUntouched();
                  control.updateValueAndValidity();
                  return control;
                });
                if (homeroomMapFrom.invalid || homeroomStrategy.invalid) {
                  this.activeSubIndex = 2;
                  this.isSchoolTechnicalFormValid = false;
                } else {
                  this.isSchoolTechnicalFormValid = true;
                }
              } else {
                this.isSchoolTechnicalFormValid = true;
              }
            } else {
              this.activeSubIndex = 1;
              this.isSchoolTechnicalFormValid = false;
            }
          } else if (
            this.activeIndex == 2 &&
            (this.activeSubIndex == 1 || this.activeSubIndex == 2)
          ) {
            this.isSchoolTechnicalFormValid = false;
          } else {
            this.isSchoolTechnicalFormValid = true;
          }
        } else {
          this.activeSubIndex = 0;
          this.isSchoolTechnicalFormValid = false;
        }
      } else {
        [syncBaseUrl, syncTokenUrl, clientID, clientSecret, sourceId].forEach(
          (control: any) => {
            control && control.markAsUntouched();
            return control;
          }
        );
        this.isSchoolTechnicalFormValid = true;
      }
    } else {
      this.updateFormValidator();
      this.isSchoolTechnicalFormValid = this.schoolTechnicalForm.valid;
    }
  }

  async onSubmit(type: string, form: FormGroup) {
    this.handleSchoolTechnicalFormValidation();
    if (this.isSchoolTechnicalFormValid) {
      if(type === 'technical' && !this.isSchoolTechnicalFormValid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill all required fields.',
        });
        return;
      }
      await this.updateOrganization(type);
      this.compareSchoolBasicData = lodash.cloneDeep(
        this.schoolBasicForm.value
      );
      this.compareSchoolTecData = lodash.cloneDeep(
        this.schoolTechnicalForm.value
      );
    } else {
      form.markAllAsTouched();
    }
    this.onValueChange();

   let selectedDropdown =  this.representativeList.filter((e: any) => e.key == this.schoolBasicForm.value.administratorRepId)
   let dropdownList = selectedDropdown[0];
   if(!!dropdownList){
     dropdownList.isPrimaryContact = true;
     dropdownList.isVisibleToSchool = true;
     this.onChangeCheckBoxVal(dropdownList);
   }
  }

  get getSchoolBasicFormControls() {
    return this.schoolBasicForm.controls;
  }

  get getSchoolTechnicalFormControls() {
    return this.schoolTechnicalForm.controls;
  }

  async updateOrganization(type: string) {
    const organizationId = this.schoolData?.key;
    let orgData: any = {
      orgName: this.schoolBasicForm.get('orgName').value || '',
      shippingState: this.schoolBasicForm.get('shippingState').value || '',
      shippingCity: this.schoolBasicForm.get('shippingCity').value || '',
      shippingAddress: this.schoolBasicForm.get('shippingAddress').value || '',
      shippingPostCode:
      this.schoolBasicForm.get('shippingPostCode').value || '',
      administratorName:
      `${this.schoolBasicForm.get('administratorFirstName').value} ${
        this.schoolBasicForm.get('administratorLastName').value
      }` || '',
      administratorRepId: this.schoolBasicForm.get('administratorRepId').value || null,
      districtID: this.schoolBasicForm.get('districtID').value || null,
      Phone_Main: this.schoolBasicForm.get('phone').value || null,
      settings: {
        visuals: {
          logo: this.schoolData?.orgLogo || null,
        },
      },
      timezones: this.schoolBasicForm.get('timezone').value || null,
    };
    if (type == 'technical') {
      if(this.activeSubIndex === 0) {
        orgData = {
          syncSource: this.schoolTechnicalForm.get('syncSource').value || null,
          sourceId: this.schoolTechnicalForm.get('syncSource').value == SourceValue.CSV ? null : this.schoolTechnicalForm.get('sourceId').value,
          clientSecret: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('clientSecret').value : null,
          clientID: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('clientID').value : null,
          syncBaseUrl: this.schoolTechnicalForm.get('syncSource').value == SourceValue.CSV ? null : this.schoolTechnicalForm.get('syncBaseUrl').value || null,
          certificate: this.schoolTechnicalForm.get('syncSource').value == SourceValue.Aeries ? this.schoolTechnicalForm.get('certificate').value : null,
          syncTokenUrl: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('syncTokenUrl').value : null,
          // isNeedToSyncClass: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('isNeedToSyncClass').value : null,
        };
      }
      this.isDisabledSyncSource = this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster;
      if(this.activeSubIndex === 1) {
        let keyMappingList: any[] = [];
        if (!this.isInvalidMapping) {
          const formValue = this.schoolTechnicalForm.value;
          const dropdownKeys = Object.keys(formValue).filter(key => key.startsWith('dropdown'));
          keyMappingList = dropdownKeys.map(key => ({
            oneRosterMappingField: this.oneRosterKeys[key],
            high5MapToField: (formValue[key] == undefined || formValue[key] == 'notMapped') ? null : formValue[key]
          }));
          orgData.oneRosterKeyMapping = keyMappingList;
        }
      }
      if(this.activeSubIndex === 2){
        orgData = {
          isNeedToSyncClass: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('isNeedToSyncClass').value : null,
          oneRosterHomeroomMapping: {
            homeroomStrategy: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('homeroomStrategy').value : null,
            homeroomField: this.schoolTechnicalForm.get('syncSource').value == SourceValue.OneRoster ? this.schoolTechnicalForm.get('homeroomMapFrom').value : null,
            titleTextIncludeInHomeroom: this.schoolTechnicalForm.get('homeroomStrategy').value == 'classTitleContainSpecificText' ? this.schoolTechnicalForm.get('textIncludeInHomeroom').value : '',
            periodTextIncludeInHomeroom: this.schoolTechnicalForm.get('homeroomStrategy').value == 'classPeriodContainSpecificText' ? this.schoolTechnicalForm.get('textIncludeInHomeroom').value : '',

            // textIncludeInHomeroom: (this.schoolTechnicalForm.get('homeroomStrategy').value == 'classPeriodContainSpecificText') || (this.schoolTechnicalForm.get('homeroomStrategy').value == 'classTitleContainSpecificText') ? this.schoolTechnicalForm.get('textIncludeInHomeroom').value : null,


          }
        }
      }
      this.schoolData = {...this.schoolData, ...orgData}
    }
    if (!!organizationId) {
      if (this.imageFile || this.isLogoDeleted) {
        await new Promise((resolve, reject) => {
          try {
            this.deleteUploadDistLogo(
              this.imageFile || null,
              organizationId,
              (downloadURL) => {
                orgData.settings.visuals = {
                  logo: downloadURL || '',
                  orgDisplayName: orgData.orgName,
                };
                resolve(downloadURL);
              }
            );
          } catch (error) {
            console.log('error: ', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong. Please try again later.',
            });
            reject();
            throw error;
          }
        });
      }

      this.loaderService.show('Updating School');

      // Update ParentList
      let studioPromise: any[] = [];
      lodash.forEach(Object.keys(this.schoolData.parentList), (key: string) => {
        studioPromise.push(this.commonFun.getStudio(key));
      });

      let [studioRes, studioErr]: any = await this.commonFun.executePromise(
        Promise.all(studioPromise)
      );
      if (studioErr) {
        this.loaderService.hide();
        this.commonFun.prepareErrorMessage(studioErr);
        return;
      }
      let parentListObj: any;
      lodash.forEach(studioRes, (studio: any) => {
        if (studio.orgManagerType === this.user.studio.orgManagerType) {
          if (parentListObj) parentListObj[studio.key] = true;
          else
            parentListObj = {
              [studio.key]: true,
            };
        }
      });
      if (!lodash.isEmpty(this.schoolBasicForm.get('districtID').value))
        parentListObj[this.schoolBasicForm.get('districtID').value] = true;
      orgData.parentList = parentListObj;
      // ===== End =====
      try {
        // update organization data
        await this.db.object(`/organizations/${organizationId}`).update(orgData);

        if (this.adminIndData?.key) {
          const updateAdminObj = {
            email: this.schoolBasicForm.get('email').value || null,
            firstName: this.schoolBasicForm.get('administratorFirstName').value || null,
            lastName: this.schoolBasicForm.get('administratorLastName').value || null,
          };
          // Update data in individual node
          await this.individual.updateIndividual(this.adminIndData.key, updateAdminObj, '', organizationId);
        }

        this.loaderService.hide();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'School updated successfully!',
        });
      } catch (error) {
        this.loaderService.hide();
        console.error('Error updating School:', error);
        const errorMessage = error?.error?.message || 'Error updating Organization!';
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: errorMessage,
        });
      }
    }
  }

  deleteUploadDistLogo(file, orgId, callback: (downloadURL: string) => void) {
    const filePath = `/photos/organisations/${orgId}/orgLogo`;
    const fileRef = this.storage.ref(filePath);
    if (file) {
      const task = this.storage.upload(filePath, file);
      task.snapshotChanges().subscribe({
        next: (snapshot) => {
          if (snapshot.state === 'success') {
            console.log('Upload successful!');
            fileRef.getDownloadURL().subscribe({
              next: (downloadURL) => {
                callback(downloadURL);
              },
              error: (err) => {
                console.log(err);
              },
            });
          }
        },
      });
    } else {
      fileRef.delete().subscribe({
        next: () => {
          console.log('Image deleted successfully!');
          callback('');
        },
        error: (err) => {
          this.isBtnDisable = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later.',
          });
          throw err;
        },
      });
    }
  }

  // --- check email validation
  async checkEmailValidation() {
    let errorMsg = '';
    if (
      !this.commonFun.isBlank(
        this.schoolBasicForm.get('email').value &&
          this.schoolBasicForm.get('email').value.trim()
      ) &&
      !this.commonFun.isEmailValid(
        this.schoolBasicForm.get('email').value &&
          this.schoolBasicForm.get('email').value.trim()
      )
    ) {
      errorMsg = 'please enter valid email';
    } else if (
      this.schoolBasicForm.get('phone').value &&
      !this.commonFun.isValidPhone(this.schoolBasicForm.get('phone').value)
    ) {
      errorMsg = 'please enter valid phone number';
    }

    if (errorMsg != '') {
      this.openConfirmationDialog(
        'Error',
        errorMsg,
        null,
        null,
        false,
        false,
        false
      );
      return;
    } else {
      let existingEmail = lodash
        .chain(this.adminIndData)
        .get('email')
        .trim()
        .toLower()
        .value();
      let newEmail = lodash
        .chain(this.schoolBasicForm.value)
        .get('email')
        .trim()
        .toLower()
        .value();

      if (existingEmail != newEmail) {
        // for create
        let response = await this.updateEmailInFirebaseAuth();
        if (!response) return;
        if (response) await this.onSubmit('basic', this.schoolBasicForm);
      } else {
        await this.onSubmit('basic', this.schoolBasicForm);
      }
    }
  }

  updateEmailInFirebaseAuth() {
    return new Promise(async (resolve, reject) => {
      let apiReqData: any = {
        uid: this.adminIndData.uid,
        newEmail: this.schoolBasicForm.value.email,
      };
      let [updateEmailRes, updateEmailErr] =
        await this.commonFun.executePromise(
          this.commonFun.updateAuthEmailPassword(apiReqData)
        );
      let err = updateEmailErr;
      if (!err && lodash.get(updateEmailRes, 'status') != 1) {
        err = lodash.get(updateEmailRes, 'message');
      }
      if (err) {
        this.loaderService.hide();
        this.openConfirmationDialog(
          'Error',
          this.commonFun.prepareErrorMessage(err),
          null,
          null,
          false,
          false,
          false
        );
        resolve(false);
        return;
      }

      if (
        this.adminIndData.email ==
        lodash
          .chain(this.schoolBasicForm.value)
          .get('email')
          .trim()
          .toLower()
          .value()
      ) {
        this.orgService.updateOrgSecureData(this.schoolData?.key, {
          email: this.schoolBasicForm.value.email,
        });
      }
      // Update email in SecData
      await this.orgService.updateSecData(
        this.schoolData?.key,
        this.adminIndData?.uid,
        { email: this.schoolBasicForm.value.email }
      );
      resolve(true);
    });
  }

  checkSourceType(types: string[]): boolean {
    const sourceValue = this.schoolTechnicalForm.get('syncSource').value;
    return types.includes(sourceValue);
  }

  patchVal() {
    const administratorName = this.schoolData?.administratorName
      ? this.schoolData.administratorName.split(' ')
      : ['', ''];
    this.schoolBasicForm.patchValue({
      orgName: this.schoolData?.orgName ? this.schoolData.orgName : '',
      phone: this.schoolData?.Phone_Main
        ? this.schoolData.Phone_Main
        : '',
      email: this.adminIndData?.email ? this.adminIndData.email : '',
      shippingState: this.schoolData?.shippingState
        ? this.schoolData.shippingState
        : '',
      shippingCity: this.schoolData?.shippingCity
        ? this.schoolData.shippingCity
        : '',
      shippingAddress: this.schoolData?.shippingAddress
        ? this.schoolData.shippingAddress
        : '',
      shippingPostCode: this.schoolData?.shippingPostCode
        ? this.schoolData.shippingPostCode
        : '',
      administratorRepId: this.selectedRep ? this.selectedRep.key : '',
      districtID: this.schoolData?.districtID ? this.schoolData.districtID : '',
      administratorFirstName: administratorName[0]?.trim() || '',
      administratorLastName: administratorName.slice(1).join(' ')?.trim() || '',
      timezone: this.schoolData?.timezones ? this.schoolData.timezones : '',
    });

    this.compareSchoolBasicData = lodash.cloneDeep(this.schoolBasicForm.value);

    this.schoolTechnicalForm.patchValue({
      syncSource: this.schoolData?.syncSource
        ? this.schoolData.syncSource === 'manual'
          ? SourceValue.CSV
          : this.schoolData.syncSource
        : ''
    });
    this.patchTechnicalFormVal();
    this.compareSchoolTecData = lodash.cloneDeep(
      this.schoolTechnicalForm.value
    );
    this.isDisabledSyncSource = this.schoolTechnicalForm.value.syncSource == SourceValue.OneRoster
  }

  patchTechnicalFormVal() {
    let source = this.schoolTechnicalForm.value.syncSource || SourceValue.CSV;
    this.schoolTechnicalForm.reset();
    this.schoolTechnicalForm.controls['syncSource'].setValue(source);
    if(source === SourceValue.OneRoster) {
      this.patchKeyMappingValue();
    }
    if(this.schoolData.syncSource === source) {
      this.schoolTechnicalForm.patchValue({
        sourceId: (this.schoolData?.sourceId ? this.schoolData.sourceId : this.schoolData?.schoolCode) || '',
        clientSecret: this.schoolData?.clientSecret
          ? this.schoolData.clientSecret
          : '',
        clientID: this.schoolData?.clientID ? this.schoolData.clientID : '',
        syncBaseUrl: (this.schoolData?.syncBaseUrl ? this.schoolData.syncBaseUrl : this.schoolData.aeriesUrl) || '',
        certificate: this.schoolData?.certificate
          ? this.schoolData.certificate
          : '',
        syncTokenUrl: this.schoolData?.syncTokenUrl
          ? this.schoolData.syncTokenUrl
          : '',
        isNeedToSyncClass: this.schoolData?.isNeedToSyncClass ? this.schoolData.isNeedToSyncClass : false,
        homeroomStrategy: this.schoolData?.oneRosterHomeroomMapping?.homeroomStrategy ? this.schoolData.oneRosterHomeroomMapping.homeroomStrategy : null,
        homeroomMapFrom: this.schoolData?.oneRosterHomeroomMapping?.homeroomField ? this.schoolData.oneRosterHomeroomMapping.homeroomField : null,
        // textIncludeInHomeroom: this.schoolData?.oneRosterHomeroomMapping?.textIncludeInHomeroom ? this.schoolData?.oneRosterHomeroomMapping?.textIncludeInHomeroom : null
        textIncludeInHomeroom: this.schoolData?.oneRosterHomeroomMapping?.homeroomStrategy == 'classPeriodContainSpecificText' ? this.schoolData?.oneRosterHomeroomMapping?.periodTextIncludeInHomeroom :this.schoolData?.oneRosterHomeroomMapping?.titleTextIncludeInHomeroom,
      });
    }
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none',
      });
    });
  }


   get mappingData():FormArray {
    return this.schoolTechnicalForm.controls["mappingData"] as FormArray;
  }

  checkValidation(controlName?: string) {
    let formVal = this.schoolTechnicalForm.value;
    const keys = Object.keys(formVal);
    const high5Keys = keys.filter(key => key.startsWith('dropdown'));
    const high5Values = high5Keys.map(key => formVal[key]);
    this.isInvalidMapping = !high5Values.includes('studentID');
    if (!!controlName && !this.schoolTechnicalForm.get(controlName).value) {
      this.schoolTechnicalForm.get(controlName).patchValue('notMapped');
    }
  }

  clearDropDownVal(key: string) {
    if (!!key && !this.schoolTechnicalForm.get(key).value) {
      this.schoolTechnicalForm.get(key).patchValue('notMapped');
    } else {
      if(!!key && (this.schoolTechnicalForm.get(key).value == 'studentID' )) {
        this.isInvalidMapping = false;
      }
    }
  }

  onSubTabChange(event: any) {
    if (event.index === 1 && (!this.oneRosterAllSchoolList.length || this.isNeedToTest)) {
    //   let isSameObj = false;
    //   let { syncBaseUrl, sourceId, syncTokenUrl, clientID, clientSecret } = this.schoolTechnicalForm.value;
    //   let newConfigurationObj = { syncBaseUrl, sourceId, syncTokenUrl, clientID, clientSecret };
    //   let oldConfigurationObj: any;
    //   if (!oldConfigurationObj) {
    //       oldConfigurationObj = newConfigurationObj;
    //   } else {
    //     isSameObj = lodash.isEqual(newConfigurationObj, oldConfigurationObj);
    //     oldConfigurationObj = newConfigurationObj;
    //   }
    //   if(!isSameObj) {
    //     this.testOneRosterConfiguration();
    //   }
       this.testOneRosterConfiguration(event);
    } else if(event.index == 2  && this.schoolTechnicalForm.value.isNeedToSyncClass && !this.oneRosterAllSchoolList.length){
      if(!this.oneRosterAllSchoolList.length || this.isNeedToTest)
      this.testOneRosterConfiguration(event);
    }
  }

  checkedOneRosterSync() {
    let isChecked = this.schoolTechnicalForm.value.isNeedToSyncClass;
    if(!!isChecked && (!this.homeRoomRes?.result?.oneRosterclassTitleContainSpecificTextInds?.length &&
      !this.homeRoomRes?.result?.oneRosterclassPeriodContainSpecificTextInds?.length &&
      !this.homeRoomRes?.result?.oneRosterCommonTeacherClassInds?.length &&
      !this.homeRoomRes?.result?.oneRosterHomeRoomclassInds?.length)) {
      this.testOneRosterConfiguration();
    }
  }

  addKeyMappingControls() {
    this.oneRosterKeyList.forEach((item, index) => {
      const controlName = 'dropdown' + index;
      this.schoolTechnicalForm.addControl(controlName, this.fb.control('notMapped'));
      this.oneRosterKeys[controlName] = item.oneRosterField;
    });
  }

  patchKeyMappingValue() {
    if(this.schoolData && Array.isArray(this.schoolData.oneRosterKeyMapping)) {
      this.schoolData.oneRosterKeyMapping.map((item, i) => {
        const controlName = `dropdown${i}`;
        if (this.schoolTechnicalForm.controls[controlName]) {
          this.schoolTechnicalForm.get(controlName)?.patchValue(item.high5MapToField || 'notMapped');
        }
      });
    } else {
       // default patch OrganizationID in identifier
       this.oneRosterKeyList.forEach((item, i) => {
        if(item.oneRosterField === 'identifier') {
          const controlName = `dropdown${i}`;
          const control = this.schoolTechnicalForm.get(controlName);
          control.patchValue("studentID");
        }
      });
    }
  }

  oneRosterAllSchoolList: any[] = [];
  async testOneRosterConfiguration(event?: any) {
    const { syncTokenUrl, clientID, clientSecret, syncBaseUrl } = this.schoolTechnicalForm.controls;
    try {
      this.loaderService.show();
      const payload = {
        clientID: clientID.value,
        clientSecret: clientSecret.value,
        syncBaseUrl: syncBaseUrl.value,
        syncTokenUrl: syncTokenUrl.value,
        isGettingSchools: true
      };
      const [accessTokenRes, accessTokenErr] = await this.commonFun.executePromise(
        this.apiHelperService.postToCloudFn(CloudFnNames.testOneRosterCredential, payload)
      );
      this.oneRosterAccessToken = lodash.get(accessTokenRes, 'result.token', '');
      if (accessTokenErr || !lodash.get(accessTokenRes, "result.success", false)) {
        console.error(accessTokenErr);
        this.loaderService.hide();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid oneRoster configuration.' });
        this.activeSubIndex = 0;
      } else {
        let schoolListRes = lodash.get(accessTokenRes, 'result.schoolList', []);
        if(schoolListRes.length == 0) {
          this.loaderService.hide();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'Schools not found',
          });
          return;
        }
        this.isNeedToTest = false;
        this.oneRosterAllSchoolList = schoolListRes;
        if(this.oneRosterAllSchoolList.length > 0) {
          if(event?.index == 1) {
            this.activeSubIndex = 1;
          } else if(event?.index == 2) {
            this.activeSubIndex = 2;
          }
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'OneRoster configuration test successful.' });
        this.getIndividualsList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getIndividualsList() {
    this.loaderService.show();
    let sourceId = this.schoolTechnicalForm.value.sourceId;
    if(!sourceId) {
      this.loaderService.hide();
      return;
    }
    let [oneRosterDataRes, oneRosterDataError] =
      await this.commonFun.executePromise(
        this.apiHelperService.postToCloudFn(
          CloudFnNames.getOneRosterMappingFields,
          {
            clientID: this.schoolTechnicalForm.value.clientID,
            clientSecret: this.schoolTechnicalForm.value.clientSecret,
            syncBaseUrl: this.schoolTechnicalForm.value.syncBaseUrl,
            syncTokenUrl: this.schoolTechnicalForm.value.syncTokenUrl,
            oneRosterAccessToken: this.oneRosterAccessToken,
            sourceId,
            isNeedToSyncClass: this.schoolTechnicalForm.value.isNeedToSyncClass || false,
          }
        )
      );
    // --- error handling
    if (oneRosterDataError) {
      this.loaderService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Something went wrong. please check console for more details.',
        });
        return;
    }

    if (oneRosterDataRes && oneRosterDataRes.result && !oneRosterDataRes.result.success) {
      this.loaderService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'Something went wrong. please check console for more details.',
      });
      await this.commonFun.handleError(
        new Error(oneRosterDataRes.result.message)
      );
      return;
    }
    this.oneRosterKeyList = [];
    if(oneRosterDataRes && lodash.get(oneRosterDataRes, "result.data", [])) {
      oneRosterDataRes.result.data.forEach((item: string) => {
        this.oneRosterKeyList.push({oneRosterField: item});
      });
      this.addKeyMappingControls();
      this.patchKeyMappingValue();
    }
    this.homeRoomRes = oneRosterDataRes;
    this.totalStaffList = [];
    this.totalStudentList = [];
    this.totalGuardianList = [];
    if(oneRosterDataRes && lodash.get(oneRosterDataRes, "result.oneRosterIndividualList", [])) {
      oneRosterDataRes.result.oneRosterIndividualList.forEach((ind) => {
        let indRole = ind.role ? ind.role : ind.roles[0].role
        if(indRole.toLowerCase() == 'student') {
          this.totalStudentList.push(ind);
        } else if(indRole.toLowerCase() == 'staff') {
          this.totalStaffList.push(ind);
        } else if(indRole.toLowerCase() == 'guardian') {
          this.totalGuardianList.push(ind);
        }
      })
    }
    if(this.schoolData?.oneRosterHomeroomMapping) {
      this.onValueChange();
    }

    if(oneRosterDataRes && lodash.get(oneRosterDataRes, "result.oneRosterIndividualList", [])) {
      this.individualList = oneRosterDataRes.result.oneRosterIndividualList.map((item) => {
        return {...item, fullName: `${item.familyName} ${item.givenName}`, optionLabel: `${item.familyName} ${item.givenName} (${item.role ? item.role : item.roles[0].role})`}
      });
      if(!!this.individualList && this.individualList.length > 0) {
        this.schoolTechnicalForm.controls['individual'].setValue(this.individualList[0].fullName);
        this.onIndividualChange(this.individualList[0].fullName);
      }
    }
    this.loaderService.hide();
  }

  onIndividualChange(ind: string) {
    lodash.forEach(this.oneRosterKeyList, (field) => {
      field.sample = null;
    });
    let individualList = lodash.filter(this.individualList, item => item.fullName === ind);
    lodash.forEach(this.oneRosterKeyList, (field) => {
      lodash.forEach(individualList, (individual) => {
        let key = field.oneRosterField;
        if (individual.hasOwnProperty(key)) {
          field.sample = individual[key];
        }
        lodash.forEach(individual.userIds, (userIds) => {
          if(userIds.type == key) {
            field.sample = userIds.identifier
          }
        })
      })
    })
  }

  get dialogHeader(): string {
    return this.schoolTechnicalForm.value.homeroomStrategy === 'classTitleContainSpecificText'
      ? 'Class titles (this is for reference only)'
      : 'Class periods (this is for reference only)';
  }

  onSelectCol(column: any) {
    this.showAllClassTitles = false;
    this.schoolTechnicalForm.controls['textIncludeInHomeroom'].setValue(column);
    this.messageService.add({
      severity: 'info',
      detail: `'${column}' is selected for sync`,
    });
    this.searchHomeroom();
  }

}
