import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ChipsModule } from 'primeng/chips';
import { CalendarComponent } from '../../../shared/components/calendar/calendar.component';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadDialogComponent } from '../../../shared/modals/file-upload-dialog/file-upload-dialog.component';
import { CommonServiceService } from '../../../shared/services/common-service.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from '../../../../environments/environment.stage';
import lodash from 'lodash';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoaderComponent } from '../../../shared/modals/loader/loader.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { EventService } from '../../../shared/services/event/event.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from '../../../shared/services/user/user.service';
import { Subscription, lastValueFrom } from 'rxjs';
import Papa from 'papaparse';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { IndividualApiService } from '../../../shared/services/individuals/individual.service';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { AlbumType, AlbumTypeOrgKey, Role } from '../../../shared/constants/enums';

@Component({
  selector: 'app-image-content',
  standalone: true,
  imports: [
    SharedModule,
    ChipsModule,
    CalendarComponent,
    CardModule, 
    DropdownModule, 
    FileUploadDialogComponent,
    LoaderComponent,
    ProgressBarModule,   
  ],
  templateUrl: './image-content.component.html',
  styleUrl: './image-content.component.scss'
})
export class ImageContentComponent implements OnInit, AfterViewInit {

  @ViewChild('albumNameIp') albumNameIp: ElementRef;
  albumName: string;
  imgCount: number = 1234
  fileDialog: boolean = false;
  loaderDialog: boolean = false;
  allEventsList: any[] = [];
  relatedEvent: any[] = [];
  keywords: string[] = [];
  imageFiles: any[] = [];
  previewImageList: any[] = [];
  imageClass: string = '';
  alertHeader: string = '';
  alertMessage: string = '';
  imageClassList: any[] = [
    {
      name: 'Portrait',
    },
    {
      name: 'Candid',
    },
    {
      name: 'Group',
    },
  ];
  selectedImageClass: any;
  imageContentList: any[] = [];
  removedImageList: any[] = [];
  showAlert: boolean = false;
  isFromAdd: boolean = false;
  isFromEvent: boolean = false;
  editEventData: any;
  albumMetaData: any;
  orgId: string = '';
  albumId: string = null;
  prevRelatedEvents: any[] = [];
  eventData: any;
  albumDataList: any[] = [];
  orgList: any[] = [];
  csvFile: any;
  pageData: any;
  comparePageData: any;
  albumType: string;
  currentAlbum: any;
  orgSubscription: Subscription;

  constructor (
    private commonFun: CommonServiceService,
    private db: AngularFireDatabase,
    private sanitizer: DomSanitizer,
    private router: Router,
    private eventService: EventService,
    private messageService: MessageService,
    private user: UserService,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    private individualApiService: IndividualApiService,
    private organizationService: OrganizationService,
  ) {
    this.getAllOrgs()
    this.commonFun.goBackButton$.next({ isShow: true })
    let navigationState = this.router.getCurrentNavigation().extras.state;
    if (navigationState) {
      this.isFromAdd = navigationState['isFromAdd'];
      this.isFromEvent = navigationState['isFromEvent'];
      this.eventData = navigationState['eventData'];
      this.albumDataList = navigationState['albumDataList'];
      this.albumId = navigationState['albumId'];
      this.orgId = navigationState['orgId'];
      let albumName = navigationState['eventData']?.meta?.displayName;
      if (albumName && this.isFromAdd) {
        let displayName = albumName;
        let count = 1;
      
        while (
          this.albumDataList.some((album) => album.meta.displayName === displayName)
        ) {
          displayName = albumName + ' ' + `(${count})`;
          count++;
        }
        this.albumName = displayName;
      }
    }
  }

  ngAfterViewInit() {
    if (this.albumNameIp) {
      this.albumNameIp.nativeElement.focus();
    }
  }
  imageTypeList: string[] = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff', 'svg+xml', 'webp', 'heic', 'icon', 'photoshop'];
  currentReportingYear: string|number;
  async ngOnInit() {
    await this.getAllEventsOfOrg(this.orgId);
    if (lodash.isEmpty(this.albumId) && lodash.isEmpty(this.orgId)) return;
    if (!this.isFromAdd) {
      this.relatedEvent = [];
      let album: any = await this.eventService.getAlbumData(this.orgId, this.albumId);
      let albumData = album.val();
      this.currentAlbum = albumData
      this.currentReportingYear = this.currentAlbum?.meta?.cycle;
      this.imageContentList = albumData.content ? this.commonFun.convertObjToArr(albumData.content) : [];
      this.imageContentList = this.imageContentList.map(
        imageContent =>{
          let isImage = imageContent?.content?.originalFilename
          ? this.imageTypeList.includes(
            lodash.last(
              imageContent?.content?.originalFilename.split('.')))
          : false;
          imageContent.content.isImage = isImage;
          return imageContent;
        })

      this.albumMetaData = albumData?.meta;
      this.albumName = this.albumMetaData?.displayName || '';
      this.keywords = this.albumMetaData?.keywords || [];
      if (this.albumMetaData?.relatedEvent?.length > 0) {
        let relEventList = this.allEventsList
          .filter((ev) => {
            return this.albumMetaData.relatedEvent.some((event) => {
              return event?.eventId === ev?.key;
            });
          })
          .map((event) => {
            let data:any = {};
            data.eventName = event?.meta?.displayName || '';
            data.cycle = event?.meta?.cycle;
            (data.eventId = event?.key),
            data.isAdded = true
            return data;
          });
        this.relatedEvent = relEventList;
      }
      this.selectedImageClass = this.albumMetaData?.imageClass ? {name: this.albumMetaData.imageClass} : '';
      
      this.prevRelatedEvents = lodash.cloneDeep(this.relatedEvent);
    } else {
        this.currentReportingYear = await this.commonFun.getReportingYear(this.user.studioID, Role.STUDIO);
    }
    if (this.isFromEvent && this.isFromAdd) this.relatedEvent.push({eventId: this.eventData.eventId, eventName: this.eventData.meta.displayName, isAdded: true})
    lodash.forEach(this.allEventsList, (event: any) => {
      event.isAdded = lodash.find(this.relatedEvent, (e: any) => e.eventId == event.key) ? true : false
    })

    this.pageData = {
      imageContentList:this.imageContentList,
      albumMetaData:this.albumMetaData,
      albumName: this.albumName,
      keywords: this.keywords,
      relatedEvent: this.relatedEvent,
      selectedImageClass: this.selectedImageClass
    }

    this.comparePageData = lodash.cloneDeep(this.pageData)

    // Show back Button
    this.commonFun.goBackButton$.next(
      { 
        isShow: true, 
        navigateUrl: this.isFromEvent ? 'events' : 'albums', 
        pageTitle: this.isFromEvent ? 'Events' : 'Albums', 
        stateData: this.isFromEvent ? {eventData: this.eventData} : { orgId: this.orgId },
      }
    );
  }

  onValueChanged(){
    this.pageData = {
      imageContentList:this.imageContentList,
      albumMetaData:this.albumMetaData,
      albumName: this.albumName,
      keywords: this.keywords,
      relatedEvent: this.relatedEvent,
      selectedImageClass: this.selectedImageClass
    }
  }


  onOrgChange(orgId) {
    if (orgId) {
      this.relatedEvent = []
      this.getAllEventsOfOrg(orgId)
    }
  }

  async getAllOrgs() {
    this.orgSubscription = this.organizationService.studioSchoolList.subscribe({
      next: (organizations) => {
        this.orgList = [];
        if (organizations?.length > 0) {
          this.orgList = organizations || []
        }
      },
      error: (error) => {
        console.error('Error getting organizations:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.commonFun.prepareErrorMessage(error) || 'Something went wrong, Please try again later.' });
        throw error;
      },
    });
  }

  async getAllEventsOfOrg(orgId) {
    this.allEventsList = [];
    let allEventsObj: any = await (await this.eventService.getAllEventsOfOrg(orgId)).val();
    if (!lodash.isEmpty(allEventsObj)) {
      let eventList: any = [];
        Object.entries(allEventsObj).forEach(([cycle, allEventsObj]) => {
          Object.entries(allEventsObj).forEach(([eventKey, events]) => {
            const event = {
              cycle,
              key: eventKey,
              ...events,
            };
            eventList.push(event);
          });
        });
        this.allEventsList = eventList
    }
  }

  addRemoveRelatedEvents(eventId: string, isAttach: boolean, index?: number) {
    if (isAttach) {
      let i = this.allEventsList.findIndex((event: any) => event.key == eventId);
      let eventData = lodash.filter(this.allEventsList, (event: any) => event.key == eventId)[0]
      this.allEventsList[i].isAdded = true;
      this.relatedEvent.push({
        eventName: eventData.meta.displayName,
        eventId: eventId
      });
    } else {
      this.relatedEvent.splice(index, 1);
      let i = this.allEventsList.findIndex((event: any) => event.key == eventId);
      this.allEventsList[i].isAdded = false;
    } 
  }

  showDialog() {
    if (lodash.isEmpty(this.albumName)) {
      this.alertHeader = 'Enter Album Name'
      this.alertMessage = 'Please enter the name of the album.'
      this.showAlert = true;
      return;
    } else if (lodash.isEmpty(this.orgId)) {
      this.alertHeader = 'Select Organization'
      this.alertMessage = 'Please select an organization before proceeding.'
      this.showAlert = true;
      return
    } 
    this.fileDialog = true;
  }

  openLoader() {
    this.loaderDialog = true;
  } 
  closeLoader() {
    this.loaderDialog = false;
  }

  async closeModel(event){
    this.fileDialog = false;
    if (event) {
      this.imageFiles = event.imageFiles && event.imageFiles;
      this.csvFile = event.csvFile && event.csvFile;
      this.albumType = event.albumType;
      await this.onSubmit();
    }
  }

  generateImage(file) {
    const objectUrl = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(objectUrl);
  }

  async removeImage(index: number) {
    if (this.imageContentList[index]) {
      this.imageContentList[index].meta.isDeleted = true;
      this.albumMetaData.imageCount--;
    }
  }

  deleteImage(event: Event, index) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Are you sure You want to delete <b>${ this.imageContentList[index].content.originalFilename }</b> image?`,
      header: 'Delete Image',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",
      accept: () => {
        this.removeImage(index);
      }
    });
  }

  async updateDataInDB() {
    if (lodash.isEmpty(this.albumName)) {
      this.alertHeader = 'Enter Album Name'
      this.alertMessage = 'Please enter the name of the album.'
      this.showAlert = true;
      return
    } 
    if (lodash.isEmpty(this.orgId)) {
      this.alertHeader = 'Select Organization'
      this.alertMessage = 'Please select an organization before proceeding.'
      this.showAlert = true;
      return
    } 
    // if (!this.albumMetaData) {
    //   this.alertHeader = 'Error';
    //   this.alertMessage = 'Albums must contain at least one file.'
    //   this.showAlert = true;
    //   return;
    // }
    let filterImageList = this.imageContentList.filter(data => !lodash.get(data, 'meta.isDeleted', false))
    let object = {
      content: {
        ...this.commonFun.convertArrToObj(filterImageList)
      },
      meta: {
        ...this.albumMetaData,
        displayName: this.albumName,
        keywords: this.keywords.length ? this.keywords : [],
        imageCount: this.albumMetaData?.imageCount || 0,
        imageClass: this.selectedImageClass?.name || '',
        relatedEvent: this.relatedEvent.length ? this.relatedEvent : [],
        [this.isFromAdd ? 'createdAt' : 'updatedAt']: moment().valueOf()
      }
    };
    this.albumId = this.isFromAdd ? this.commonFun.createFirebasePushId() : this.albumId;
    let photoPath = `portal/assets/${this.user.studioID}/${this.orgId}/${this.albumId}/`;
    this.eventService.updateAlbumData(photoPath, object);
    if(this.currentAlbum?.meta?.albumType && this.albumId) {
      this.updateOrgAlbum(this.currentAlbum.meta.albumType, this.albumId);
    }
    
    await this.addRemoveAssetIdToEvent();

    lodash.forEach(this.imageContentList, (img: any) => {
      if (lodash.get(img, 'meta.isDeleted', false)) {
        this.eventService.deleteImage(this.orgId, this.albumId, img.key, img?.content?.photoUrl)
      }
    });

    const routeParams = this.isFromEvent
    ? {
        state: {
          data: {
            eventData: {
              type: 'edit',
              event: this.eventData,
            },
          },
        },
      }
    : { state: { orgId: this.orgId } };
   this.comparePageData = lodash.cloneDeep(this.pageData)
   this.router.navigate([this.isFromEvent ? 'events' : 'albums'], routeParams);
  }

  async addRemoveAssetIdToEvent() {
    // update assetId in events assets
    let addEventIds = lodash.differenceBy(this.relatedEvent, this.prevRelatedEvents, 'eventId');
    let removedEventIds = lodash.differenceBy(this.prevRelatedEvents, this.relatedEvent, 'eventId');

    let promises: any[] = [];

    // add assetsId in events start
    lodash.forEach(addEventIds, (event: any) => {
      promises.push(this.eventService.getAssetsOfEvent(this.orgId, event.eventId, event.cycle || this.currentReportingYear));
    });
    let [resSnapshot, err] = await this.commonFun.executePromise(Promise.all(promises))
    if (err) {
      console.log('err: ', err);
      return;
    }
    let assetResDataList: any[] = [];
    lodash.forEach(resSnapshot, (res: any) => {
      assetResDataList.push(res.val())
    })
    if (!!(assetResDataList) && this.orgId) {
      assetResDataList.forEach((res: any[], i) => {
        if (res) assetResDataList[i].push(this.albumId)
        else assetResDataList[i] = [this.albumId]
      })
      lodash.forEach(addEventIds, (event: any, i) => {
        this.eventService.updateAssetsOfEvent(this.orgId, event.eventId, event?.cycle || this.currentReportingYear, assetResDataList[i])
      });
    }
    // add assetsId in events end

    promises = [];

    // Remove assetsId from events start
    lodash.forEach(removedEventIds, (event: any) => {
      promises.push(this.eventService.getAssetsOfEvent(this.orgId, event.eventId, event?.cycle || this.currentReportingYear));
    });
    let [snapshotRes, snapshotErr] = await this.commonFun.executePromise(Promise.all(promises))
    if (snapshotErr) {
      console.log('err: ', err);
      return;
    }
    let removeAssetResDataList: any[] = [];
    lodash.forEach(snapshotRes, (res: any) => {
      removeAssetResDataList.push(res.val())
    })
    lodash.forEach(removeAssetResDataList, (res: any) => {
      const index = res.findIndex((e) => e === this.albumId);
      if (index !== -1) res.splice(index, 1);
    })
    lodash.forEach(removedEventIds, (event: any, i) => {
      this.eventService.updateAssetsOfEvent(this.orgId, event.eventId, event?.cycle || this.currentReportingYear, removeAssetResDataList[i])
    })
    // Remove assetsId from events end
  }

  parseCsv = (file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          resolve(this.commonFun.convertKeysToLowerCase(result.data));
        },
        error: (parsingError) => {
          reject(parsingError);
        }
      });
    }).catch((parseError) => {
      console.error('Error parsing CSV:', parseError);
      throw parseError; // Re-throwing the error for further handling if needed
    });
  };

  getValueByKeyIgnoreCase = (obj, key) => {
    const keys = Object.keys(obj);
    const foundKey = keys.find(k => (k.trim()).toLowerCase() === (key.trim()).toLowerCase());
    return foundKey ? obj[foundKey] : undefined;
  }

  async onSubmit() {
    // Function to clean indIDs list from image content meta data
    const updateIndIDs = (data) => {
      const groupedIndIDs = lodash.groupBy(data.meta.indIDs, 'indID_Client');
      data.meta.indIDs = lodash.flatMap(groupedIndIDs, (group) => {
        if (group.length === 1) {
          return group;
        } else {
          return [lodash.last(group)]; // Keep only the last element if duplicates exist
        }
      });
    };

    let CSVRes: boolean = false;
    let jsonData: any[] = [];

    if (this.csvFile) {
      jsonData = await this.parseCsv(this.csvFile);
      jsonData = this.commonFun.fieldMappingSynonymsCSVData(jsonData);
      jsonData = lodash.filter(jsonData, (obj: any) => obj.hasOwnProperty('indid_client') && obj.hasOwnProperty('isprimary') && obj.hasOwnProperty('photo'))
      CSVRes = await this.checkCSVFile(jsonData);
      if (!CSVRes) return
    }
    
    if (lodash.isEmpty(this.albumName)) {
      this.alertHeader = 'Enter Album Name'
      this.alertMessage = 'Please enter the name of the album.'
      this.showAlert = true;
    } else if (this.imageFiles.length == 0 && !this.csvFile) {
      this.alertHeader = 'Image Selection Alert'
      this.alertMessage = 'No new image has been selected. Please select an image.'
      this.showAlert = true;
      return;
    } else if (this.imageFiles.length == 0 && this.csvFile) {
      let albumId = this.isFromAdd ? this.commonFun.createFirebasePushId() : this.albumId;
      let photoPath = `portal/assets/${this.user.studioID}/${this.orgId}/${albumId}/`;

      if (this.csvFile) {
        let [csvJsonRes, csvJsonErr] = await this.commonFun.executePromise(this.parseCsv(this.csvFile));
        if (csvJsonErr) {
          this.loaderDialog = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.commonFun.prepareErrorMessage(csvJsonErr) || 'Something went wrong, Please try again later.',
          });
          return null;
        }

        if (jsonData && CSVRes) {
         let tempImageContactList: any[] = this.imageContentList.map((image) => {
            let indIDs = jsonData.filter(indData => lodash.toLower(this.getValueByKeyIgnoreCase(indData, 'photo')) == lodash.toLower(image.content.originalFilename.trim()))
              .map((indData) => {
                return {
                  indID_Client: this.getValueByKeyIgnoreCase(indData, 'indID_client') || null,
                  isPrimary: !!this.getValueByKeyIgnoreCase(indData, 'isPrimary') ? true : false
                }
              });
            if (!lodash.isEmpty(indIDs)) {
              image.meta.indIDs = (image.meta && !!image.meta?.indIDs) ? [...image.meta?.indIDs, ...indIDs] : indIDs;
            }
            return image;
          })

          // Loop through each object in the data and update the indIDs array
          lodash.forEach(this.imageContentList, (item) => {
            updateIndIDs(item);
          });

          let photoDataObj = {
            content: {
              ...this.commonFun.convertArrToObj(tempImageContactList),
            },
            meta: {
              cycle: this.currentReportingYear,
              [this.isFromAdd ? 'createdAt' : 'updatedAt']: moment().valueOf(),
              displayName: this.albumName,
              keywords: !!(this.keywords.length) ? this.keywords : [],
              imageCount: (this.imageFiles.length + (Number(this.albumMetaData?.imageCount) || 0)),
              imageClass: this.selectedImageClass?.name || null,
              relatedEvent: !!(this.relatedEvent.length) ? this.relatedEvent : [],
              albumType: this.albumType
            }
          }
          this.db.object(`/${photoPath}`).update(photoDataObj);
        }
      }
    } else {
      this.loaderDialog = true;
      let imageData = { successCount: 0, totalImage: this.imageFiles.length }
  
      let csvJsonRes: any;
      let csvJsonErr: any;
      if (this.csvFile) {
        [csvJsonRes, csvJsonErr] = await this.commonFun.executePromise(this.parseCsv(this.csvFile));
      }
      if (csvJsonErr) {
        console.log('csvJsonErr: ', csvJsonErr);
        this.loaderDialog = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: this.commonFun.prepareErrorMessage(csvJsonErr) || 'Something went wrong, Please try again later.',
        });
        return null;
      }

      this.eventService.loaderUploadingCount$.next(imageData);
      let albumId = this.isFromAdd ? this.commonFun.createFirebasePushId() : this.albumId;
      // photos add in s3 at  'portal/assets/studioId/orgId/albumId/'
      let photoPath = `portal/assets/${this.user.studioID}/${this.orgId}/${albumId}/`;

      const uploadAlbumPhotos = async (signedURLData, file, cycle, fileFormat) => {
        if (
          !signedURLData ||
          !signedURLData.signedUrl ||
          !signedURLData.fileName ||
          !file ||
          !cycle ||
          !fileFormat
        ) {
          this.loaderDialog = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong, Please try again later.',
          });
          return { success: false };
        }
        
        // --- image upload
        let [res, imageDataUploadErr] = await this.commonFun.executePromise(
          lastValueFrom(this.commonFun
            .uploadImageOnS3(
              signedURLData.signedUrl,
              file
            ))
        );
        if (imageDataUploadErr) {
          this.loaderDialog = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: imageDataUploadErr?.error?.message ? imageDataUploadErr.error.message : 'Something went wrong, Please try again later.',
          });
          return { success: false }
        }

        let photoURL = `${environment.awsImageUpload.awsImageEndPoint}/${photoPath}${signedURLData.fileName}`;

        let photoObj = {
          content: {
            format: fileFormat,
            originalFilename: file.name,
            photoUrl: photoURL,
          },
          meta: {
            [this.isFromAdd ? 'createdAt' : 'updatedAt']: moment().valueOf(),
            type: "image",
            relatedEvents: [...this.relatedEvent]
          }
        }
        return { success: true, photoObj };
      };

      // --- loop through update data and update individual photos
      let successCount = 0;
      let failureCount = 0;

      // --- prepare request body for getting signed URLs
      let getSignedURLsReqBody = {
        extensions: [],
        path: photoPath
      };

      let signedURLsLinkedData = [];
      lodash.each(this.imageFiles, (file, index) => {
        // --- add extension to request body extensions list
        let extension = file.type
          ? lodash.chain(file.type).split("/").last().value()
          : "png";
        getSignedURLsReqBody.extensions.push(extension);

        // --- add other data (file, indData, etc.) to linked data list
        signedURLsLinkedData.push({ file, cycle: this.currentReportingYear, fileFormat: extension });
      });

      // --- get signed URLs
      let [signedURLsData, signedURlsErr] = await this.commonFun.executePromise(
        this.commonFun.generateS3SignUrl(getSignedURLsReqBody).toPromise()
      );
      if (signedURlsErr) {
        this.commonFun.handleError(signedURlsErr);
        this.loaderDialog = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: this.commonFun.prepareErrorMessage(signedURlsErr?.error)
        });
        return null;
      }

      // --- upload ind photos
      const simultaneousReqsCount = 180;

      let signedURLsLinkedDataChunks = lodash.chunk(
        signedURLsLinkedData,
        simultaneousReqsCount
      );
      let signedURLsDataChunks = lodash.chunk(
        signedURLsData,
        simultaneousReqsCount
      );

      let index = 0;
      let errOccurred = false;
      let updatePhotosPromises = [];
      let uploadedPhotosObj: any = {};
      for (const signedURLsLinkedDataChunk of signedURLsLinkedDataChunks) {
        let signedURLsDataChunk = signedURLsDataChunks[index];

        let halfReqsPromise = new Promise((resolve) => {
          let remainingCount = signedURLsLinkedDataChunk.length;
          lodash.each(signedURLsLinkedDataChunk, (data, i) => {
            if (!data || !data.file || !data.cycle || !data.fileFormat) return;

            let signedURLData = lodash.nth(signedURLsDataChunk, i);
            if (!signedURLData) return;
            // --- update photo data
            let updatePhotoPromise = uploadAlbumPhotos(
              signedURLData,
              data.file,
              data.cycle,
              data.fileFormat
            ).then((photoUpdateRes: any) => {
              // --- update success/failure counts
              if (photoUpdateRes && photoUpdateRes.success == true) {
                uploadedPhotosObj[this.commonFun.createIndId()] = photoUpdateRes.photoObj;
                // --- update success counts
                successCount++
                let imageData = { successCount: successCount, totalImage: this.imageFiles.length }
                this.eventService.loaderUploadingCount$.next(imageData);
              } else {
                // --- update failure counts
                failureCount++;
              }
              // --- decrease remaining counter
              remainingCount--;

              if (remainingCount == 0) {
                return resolve(null);
              }
            });
            updatePhotosPromises.push(updatePhotoPromise);
          });
        });

        let [, halfReqsPromiseErr] = await this.commonFun.executePromise(
          halfReqsPromise
        );
        if (halfReqsPromiseErr) {
          errOccurred = true;
          this.loaderDialog = false;
          this.commonFun.handleError(halfReqsPromiseErr);
          break;
        }

        index++;
      }

      if (errOccurred) return null;

      let [, updatePhotosPromisesErr] = await this.commonFun.executePromise(
        Promise.all(updatePhotosPromises)
      );
      if (updatePhotosPromisesErr) {
        this.commonFun.handleError(updatePhotosPromisesErr.message);
      }

      this.imageContentList = [...this.commonFun.convertObjToArr(uploadedPhotosObj), ...this.imageContentList];
      if (csvJsonRes) {
        this.imageContentList.forEach((image) => {
          let indIDs = csvJsonRes.filter(indData => lodash.toLower(this.getValueByKeyIgnoreCase(indData, 'photo')) == lodash.toLower(image.content.originalFilename.trim()))
          .map((indData) => {
            return {
              indID_Client: this.getValueByKeyIgnoreCase(indData, 'indID_Client') || null,
              isPrimary: !!this.getValueByKeyIgnoreCase(indData, 'isPrimary') ? true : false
            }
          });
          if (indIDs) {
            image.meta.indIDs = !!(image.meta && image.meta?.indIDs) ? [...image.meta?.indIDs, ...indIDs] : indIDs;
          }
        })
      }

      // Loop through each object in the data and update the indIDs array
      lodash.forEach(this.imageContentList, (item) => {
        updateIndIDs(item);
      });

      let photoDataObj = {
        content: {
          ...this.commonFun.convertArrToObj(this.imageContentList),
          ...uploadedPhotosObj
        },
        meta: {
          cycle: this.currentReportingYear,
          [this.isFromAdd ? 'createdAt' : 'updatedAt']: moment().valueOf(),
          displayName: this.albumName,
          keywords: !!(this.keywords.length) ? this.keywords : [],
          imageCount: (this.imageFiles.length + (Number(this.albumMetaData?.imageCount) || 0)),
          imageClass: this.selectedImageClass?.name || null,
          relatedEvent: !!(this.relatedEvent.length) ? this.relatedEvent : [],
          albumType: this.albumType
        }
      }
      this.db.object(`/${photoPath}`).update(photoDataObj);
      this.updateOrgAlbum(this.albumType, albumId);

      if (this.relatedEvent?.length > 0) {
        let promises: any[] = [];
        lodash.forEach(this.relatedEvent, (event: any) => {
          promises.push(this.eventService.getAssetsOfEvent(this.orgId, event.eventId, event?.cycle || this.currentReportingYear));
        });
        let [resSnapshot, err] = await this.commonFun.executePromise(Promise.all(promises))
        if (err) {
          console.log('err: ', err);
          return;
        }
        let assetResDataList: any[] = [];
        lodash.forEach(resSnapshot, (res: any) => {
          assetResDataList.push(res.val())
        })
        assetResDataList.forEach((res: any[], i) => {
          if (res) assetResDataList[i].push(albumId)
          else assetResDataList[i] = [albumId]

          assetResDataList[i] = lodash.uniq(assetResDataList[i]);
        })
        lodash.forEach(this.relatedEvent, (event: any, i) => {
          this.eventService.updateAssetsOfEvent(this.orgId, event.eventId, event?.cycle || this.currentReportingYear, assetResDataList[i])
        });
      }

      failureCount = signedURLsLinkedData.length - successCount;
      let message = `${successCount} ${(successCount > 1) ? 'images' : 'image'} uploaded successfully.${(failureCount > 0) ? ` but ${failureCount} ${(failureCount > 1) ? 'images' : 'image'} upload failed...` : ''}`;
      this.albumId = albumId;
      this.isFromAdd = false;
      await this.ngOnInit();
      setTimeout(() => {
        this.loaderDialog = false
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: message,
        });
      }, 1000);
    }

    if (this.csvFile && this.albumType == 'id' && CSVRes) {
      this.loaderService.show();
      // let jsonData: any = await this.parseCsv(this.csvFile);
      // jsonData = lodash.filter(jsonData, (obj: any) => obj.hasOwnProperty('indid_client') && obj.hasOwnProperty('isprimary') && obj.hasOwnProperty('photo'))

      // Group data by indid_client
      const groupedJsonData = lodash.groupBy(jsonData, 'indid_client');
      // Convert grouped data to the desired format
      let groupedDataList = lodash.map(groupedJsonData, (group) => {
        return {
          indID_Client: group[0].indid_client,
          photoData: lodash.map(group, (item) => ({
            photo: item.photo,
            isPrimary: item.isprimary === "y" // Set isPrimary to true if there's only one photo or if isPrimary is "y"
            // isPrimary: group.length === 1 || item.isprimary === "y" // Set isPrimary to true if there's only one photo or if isPrimary is "y"
          }))
        };
      });
      // Merge arrays based on indID_Client
      const mergedArray = lodash.map(groupedDataList, (groupItem: any) => {
        const imageContent = lodash.filter(this.imageContentList, { meta: {indIDs: [{ indID_Client: groupItem.indID_Client }] }});
        if (imageContent) {
          groupItem['imageContent'] = imageContent;
        }
        return groupItem;
      });
      const finalIndImgData = lodash.map(mergedArray, (item: any) => {
        const primaryPhotos = item.photoData.filter(photo => photo.isPrimary);
        if (primaryPhotos.length === 0) return null; // Skip if no primary photo data
        // Sort primary photos by createdAt timestamp in descending order
        const sortedPrimaryPhotos = lodash.orderBy(primaryPhotos, ['createdAt'], ['desc']);
        const latestPrimaryPhoto = sortedPrimaryPhotos[0]; // Select the latest primary photo
        const imageUrlObject = lodash.find(item.imageContent, image => image.content.originalFilename === latestPrimaryPhoto.photo);
        return {
          indID_Client: item.indID_Client,
          imageUrl: imageUrlObject ? imageUrlObject.content.photoUrl : null,
          photoName: imageUrlObject ? imageUrlObject.content.originalFilename : null,
          isPrimary: latestPrimaryPhoto.isPrimary
        };
      }).filter(Boolean); // Filter out null values
      
      let indIDPromise: any[] = [];
      for (let imgObj of finalIndImgData) {
        indIDPromise.push(this.db.list(`individuals/${this.orgId}`, (ref) => ref.orderByChild('indID_Client').equalTo(imgObj.indID_Client)).query.once('value'));
      }
      let [res, err]: any = await this.commonFun.executePromise(Promise.all(indIDPromise));
      if (!lodash.isEmpty(err)) {
        this.commonFun.prepareErrorMessage(err);
        return;
      }
      let indList: any[] = [];
      if (!lodash.isEmpty(res)) {
        lodash.forEach(res, async (snapshot, i) => {
          indList.push(...this.commonFun.convertObjToArr(snapshot.val()));
        });
      }

      lodash.forEach(indList, async (ind, i) => {
        if (!lodash.isEmpty(ind?.indID_Client)) { 
          let foundedIndex = lodash.findIndex(finalIndImgData, (img: any) => ind?.indID_Client == img.indID_Client);
          finalIndImgData[foundedIndex]['indID'] = ind.key;
        }
      });
      await this.updateIndData(finalIndImgData);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'File uploaded successfully.',
      });
      this.loaderService.hide();
    }
  }

  errorMsg: string[] = [];
  async checkCSVFile(JSONList): Promise<boolean> {
    this.errorMsg = [];
    
    // Ind Data missing in CSV
    let notSpecifiedIndCount = lodash.filter(JSONList, (obj) => lodash.isEmpty(obj.indid_client)).length;
    if (notSpecifiedIndCount > 0) this.errorMsg.push(`${notSpecifiedIndCount > 1 ? '<b>' + notSpecifiedIndCount +'</b> are' : '<b>1</b> row is' } missing a <b>Client Individual ID</b>`);
    
    // // Photo Data missing in CSV
    let notPhotoPresentCount = lodash.filter(JSONList, (obj) => lodash.isEmpty(obj.photo)).length;
    if (notPhotoPresentCount > 0) this.errorMsg.push(`${notPhotoPresentCount > 1 ? '<b>' + notPhotoPresentCount +'</b> are' : '<b>1</b> row is' } missing a <b>photo</b>`);
   
    // Unexpected Cols ignore
    let allColsFromCSV = lodash.keys(JSONList[0]);
    let unexpectedCols = lodash.difference(allColsFromCSV, ['indid_client', 'photo', 'isprimary']);
    if (unexpectedCols.length > 0) this.errorMsg.push(`The following columns were ignored: <b>${unexpectedCols.slice(0, 5).join(', ')}</b> ${unexpectedCols.length > 5 ? 'and <b>' + (unexpectedCols.length-5) + '</b> more' : ''}`);
    
    // Required Cols missing
    let missingRequiredCols = lodash.filter(['indid_client', 'photo', 'isprimary'], (item) => !allColsFromCSV.includes(item))
    if (missingRequiredCols.length > 0) this.errorMsg.push(`The following required columns were not found: <b>${missingRequiredCols.join(', ')}</b>`);

    // Check Ind List
    let allIndOfOrg = await this.individualApiService.getAllIndByOrg(this.orgId);
    let indList: any[] = allIndOfOrg;
    if (indList && indList.length == 1) {
      this.errorMsg.push('Individuals not available in school.');
    } else {
      let DBIndIDList = indList.map((ind) => ind?.indID_Client);
      let CSVIndIDList = JSONList.map((obj) => obj?.indid_client);
      let indNotFoundInSchool = lodash.difference(CSVIndIDList, DBIndIDList)
      console.log('indNotFoundInSchool: ', indNotFoundInSchool);
      if (indNotFoundInSchool.length > 0) {
        if (indNotFoundInSchool.length > 5) {
          this.errorMsg.push(`The following individuals were referenced by Individual ID but do not exist at this school: <b>${indNotFoundInSchool.slice(0, 5).join(', ')} and ${(indNotFoundInSchool.length - 5)} more </b>`);
        } else {
          this.errorMsg.push(`The following individuals were referenced by Individual ID but do not exist at this school: <b>${indNotFoundInSchool.slice(0, 5).join(', ')}</b>`);
        }
      }
    }

    if (this.errorMsg.length > 0) {
      let msgHtml = this.errorMsg.map(msg => `<li class="text-danger">${ msg }</li>`).join(''); 
      let conformationRes = await this.openConfirmationDialog(
        'Warnings',
        msgHtml,
        'Save with warnings',
        'Cancel',
        false,
        !(missingRequiredCols.length > 0),
        true
      );
      console.log('conformationRes: ', conformationRes);
      return conformationRes;
    }
    return true;
  }

  async updateIndData(finalIndImgData: any[]) {
    let reportingYear = await this.commonFun.getReportingYear(this.orgId);
    for (let imgObj of finalIndImgData) {
      if (lodash.get(imgObj, 'indID', false)) {
        let updateIndObj: any = {
          photoStatus: 1,
          photoFromCsv: imgObj.photoName,
          photoYearL: reportingYear,
          photos: {
            [reportingYear]: {
              photoFromCsv: imgObj.photoName,
              photoStatus: 1,
              photoUpdated: moment().valueOf(),
              photoUrl: imgObj.imageUrl
            }
          }
        };
        this.db.object(`individuals/${this.orgId}/${imgObj.indID}`).update(updateIndObj);
      }
    }
  }

  dupCheckKeyWord() {
    console.log('change detected');
    this.keywords = [...lodash.uniq(this.keywords)];
  }

  // Navigate to related event
  navigateToEvent(selectedEvent) {
    let eventData = this.allEventsList.filter((event: any) => event.key == selectedEvent?.eventId)[0];
    delete eventData.isAdded;
    eventData = {
      ...eventData,
      orgId: this.orgId,
      studioId: this.user.studioID,
      cycle: selectedEvent.cycle,
      eventId: selectedEvent?.key
    }
    
    this.router.navigate(['/events'], {
      state: { data: { eventData: eventData } },
    });
    this.commonFun.pageHeader.next('Events');
  }
  async deleteAlbum() {
    this.openConfirmationDialog(
      'Confirmation',
      'Are you sure you want to delete this Album?',
      'Yes',
      'No',
      false,
      true,
      true,
       async () => {
        let obj = {
          key: this.albumId,
          meta: this.albumMetaData
        }
        let [res, err]: any = await this.commonFun.executePromise(this.eventService.deleteAlbumFromAllSide(this.orgId, obj, this.currentReportingYear));
        console.log('res: ', res);
        console.log('err: ', err);
    
        if (err) {
          console.error('Error deleting Album data:', err);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error while deleting Album data. Please try again later.',
            });
          return;
        }
        const routeParams = this.isFromEvent
        ? {
            state: {
              data: {
                eventData: {
                  type: 'edit',
                  event: this.eventData,
                },
              },
            },
          }
        : { state: { orgId: this.orgId } };
      
       this.router.navigate([this.isFromEvent ? 'events' : 'albums'], routeParams);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Album deleted successfully!',
        });
      }
    );
  }


  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }

  updateOrgAlbum(albumType: string, albumId: any) {
    let selectedAlbum = albumType ===  AlbumType.ID ? AlbumTypeOrgKey.ID : albumType === AlbumType.YEARBOOK ? AlbumTypeOrgKey.YEARBOOK : AlbumTypeOrgKey.GENERAL;
    let albumData = {
      [selectedAlbum]: albumId
    }
    this.db.object(`organizations/${this.orgId}`).update(albumData);
  }

  ngOnDestroy() {
    if(this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
  }
}
