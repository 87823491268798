import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user/user.service';
import { EventService } from '../../shared/services/event/event.service';
import { IndividualService } from '../../shared/services/individuals/individual.service';
import 'moment-timezone'
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { CommonServiceService } from '../../shared/services/common-service.service';
import { SlotStatus } from '../../shared/constants/enums';
import { MessageService } from 'primeng/api';
import { LoaderService } from '../../shared/services/loader/loader.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { firstValueFrom, Observable} from 'rxjs';
import { AuthService } from '../../shared/services/auth/auth.service';
import lodash from 'lodash';
import { OrganizationService } from '../../shared/services/organization/organization.service';
import { MomentInput } from 'moment-timezone';

@Component({
  selector: 'app-slot-booking',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './slot-booking.component.html',
  styleUrl: './slot-booking.component.scss',
})
export class SlotBookingComponent implements OnInit {
  slotPreferredDate = [];

  selectedPreferredDate: any;

  slotPreferredTime = [];
  // orgData: any;
  eventMetaData: any;
  currentSlotData: any;
  previousSlotData: any;
  indData: any;
  withoutIndIdSlotData: any;

  cancelSitting = false;
  pickNewTime = false;
  confirmSitting: boolean = false;
  confirmCancellation: boolean = false;
  orgId: string = '';
  indId: string = '';
  eventId: string = '';
  studioId: string = '';
  slotId: string = '';
  isDirectUpdate: boolean = false;
  selectedTimeSlot: any;
  isShowErrorMassage: boolean = false;
  isLoading: boolean = false;
  indName: any;
  grade: any;
  studentID: any;
  organization;
  allSlots: any[] = [];
  isGeneric: boolean = false
  slotStatus = {
    CONFIRMED: SlotStatus.CONFIRMED,
    DECLINED: SlotStatus.DECLINED,
    CHANGED: SlotStatus.CHANGED,
    PROPOSED: SlotStatus.PROPOSED,
  };
  authStateReadyObservable: Observable<any>;
  eventSubjects: string[] = [];
  isInvite: boolean = false;
  isShowLoader: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private eventService: EventService,
    private individualService: IndividualService,
    private commonFun: CommonServiceService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private db: AngularFireDatabase,
    private authService: AuthService,
    private orgService: OrganizationService
  ) {}

  eventCycle: number|string;
  async ngOnInit(): Promise<void> {
    this.loaderService.show('Getting Booking Data...');
    this.isShowLoader = true;
    // --- wait for authorization
    let authorized = await this.commonFun.waitForAuthorization(this.authService);
    if (!authorized) {
      this.loaderService.hide();
      this.isShowLoader = false;
      return;
    }

    this.isLoading = true;

    this.isLoading = false;
    let params: any = await firstValueFrom(this.route.queryParams)

      if ('orgId' in params) {
        this.orgId = params.orgId;
        this.userService.orgID = this.orgId;
      } else if (this.userService?.orgID) {
        this.orgId = this.userService.orgID || '';
      }

      if ('indId' in params) {
        this.indId = params.indId;
      }

      if ('eventId' in params) {
        this.eventId = params.eventId;
      }

      if ('studioId' in params) {
        this.studioId = params.studioId;
        this.userService.studioID = this.studioId;
      } else if (this.userService.studioID) {
        this.studioId = this.userService.studioID;
      }

      if ('orgName' in params) {
        this.organization = params.orgName?.replace(/%/g, ' ') || '';
      } else if (!!this.userService?.org?.orgName) {
        this.organization = this.userService.org.orgName;
      }

      if ('indName' in params) {
        this.indName = params.indName?.replace(/%/g, ' ') || '';
      }

      if ('studentID' in params) {
        this.studentID = params.studentID?.replace(/%/g, ' ') || '';
      }

      if ('grade' in params) {
        this.grade = params.grade?.replace(/%/g, ' ') || '';
      }

      if ('slotId' in params) {
        this.slotId = params.slotId;
      }

      if('isGeneric' in params) {
        this.isGeneric = params.isGeneric;
      }

      if('cycle' in params){
        this.eventCycle = params.cycle;
      }

      if(!this.orgId || !this.studioId){
        this.router.navigate(['/generic'])
      }
      this.orgService.setOrgTimezone(this.orgId)
      this.isLoading = true
      try {
        const [slotData, metaData] = await Promise.all([
          // this.userService.getOrgData(this.orgId),
          this.getCurrentSlotData(),
          // this.getIndData(),
          this.getEventData(),
        ]);
        if ('isConfirm' in params) {
          this.isDirectUpdate = true;
          if (params.isConfirm == 'true') {
            await this.confirmSlot();
          }

          if (params.isConfirm === 'false') {
            this.cancelSitting = true;
          }
          return;
        }
        // this.orgData = orgData;
        this.loaderService.hide();
        this.isShowLoader = false;
      } catch (err) {
        this.isLoading = false;
        this.loaderService.hide();
        this.isShowLoader = false;
      } finally {
        this.isLoading = false;
        this.loaderService.hide();
        this.isShowLoader = false;
      }
  }

  async getCurrentSlotData() {
    let slotData = await this.eventService.getSlotByIndId(
      this.studioId,
      this.orgId,
      this.eventId,
      this.indId, 
      this.eventCycle
    );

    if (!!slotData) {
      slotData?.forEach((childSnapshot: DataSnapshot) => {
        this.currentSlotData = {
          ...childSnapshot.val(),
          key: childSnapshot.key,
        };
      });
    }
    if (!this.currentSlotData['slotStartTimeString']) {
      this.currentSlotData['slotStartTimeString'] = this.commonFun.moment(
        this.currentSlotData.startTime
      ).format('h:mm a z [on] MMMM D, YYYY');
    }

    let previousSlot = (
      await this.eventService.getSlotById(
        this.studioId,
        this.orgId,
        this.eventId,
        this.slotId, 
        this.eventCycle
      )
    ).val();
    if (previousSlot) {
      previousSlot['slotStartTimeString'] = this.commonFun.moment(
        previousSlot.startTime
      ).format('h:mm a z [on] MMMM D, YYYY');
      if (this.currentSlotData.key != this.slotId) {
        this.previousSlotData = previousSlot;
      }
    }
  }

  async getIndData() {
    this.indData = await this.individualService.getIndividualFromDB(
      `individuals/${this.orgId}/${this.indId}`,
      null
    );
  }

  async getEventData() {
    let eventDataSnapShot = await this.eventService.getEventById(
      this.eventId, 
      this.eventCycle,
      this.orgId,
      this.studioId,
    );
    let eventData = eventDataSnapShot.val();
    this.eventMetaData = eventData.meta;
    this.eventSubjects = lodash.get(eventData, 'subjects', []);
    this.isInvite = lodash.includes(this.eventSubjects, this.indId);
  }

  async getPickNewTime() {
    let slots = await (
      await this.eventService.getAllSlots(
        this.studioId,
        this.orgId,
        this.eventId, 
        this.eventCycle
      )
    ).val();
    this.allSlots = !!slots ? this.commonFun.convertObjToArr(slots) : [];
    let withoutIndIdSlotData = this.allSlots.filter((slot) => {
      return (
        (!slot.hasOwnProperty('indID') ||
          slot.status_appointment === SlotStatus.DECLINED) &&
        slot?.type !== 'lunch'
      );
    });

    let uniqueDate = this.getUniqueDates(withoutIndIdSlotData);
    this.withoutIndIdSlotData = withoutIndIdSlotData;

    this.slotPreferredDate = uniqueDate;
  }

  getUniqueDates(
    events: any[],
    isTime = false
  ): { date: string; millisecond: number }[] {
    const startTimes = events.map((event) => {
      let obj = {
        ...event,
        startTime: this.commonFun.moment(event.startTime),
      };
      return obj;
    });
    const uniqueDates: { date: string; millisecond: number; slotId: String }[] =
      [];

    for (const date of startTimes) {
      const dateString = isTime
        ? date.startTime.format('hh:mm a')
        : date.startTime.format('YYYY-MM-DD');
      const existingIndex = uniqueDates.findIndex(
        (item) => item.date === dateString
      );

      if (existingIndex === -1) {
        uniqueDates.push({
          date: dateString,
          millisecond: date.startTime.valueOf(),
          slotId: date.key,
        });
      }
    }
    uniqueDates.sort((a, b) => a.millisecond - b.millisecond);
    return uniqueDates;
  }

  preferredValueChange() {
    const dateInMillis = this.selectedPreferredDate.millisecond; // Example date in milliseconds
    const result = this.getDataForDay(this.withoutIndIdSlotData, dateInMillis);
    this.slotPreferredTime = this.getUniqueDates(result, true);
  }

  getDataForDay(data: any, dateInMillis: number) {
    const targetDate = this.commonFun.moment(dateInMillis);
    return data.filter(
      (item) =>
        this.commonFun.moment(item.startTime).isSame(targetDate, 'day') &&
        item.type === 'booking'
    );
  }

  async pickNewSlotTime() {
    this.isShowErrorMassage = true;
    if (!this.selectedPreferredDate || !this.selectedTimeSlot) {
      return;
    }
    // Check if the slot is already booked or not
    const currentSlotData = (
      await this.eventService.getSlotById(
        this.studioId,
        this.orgId,
        this.eventId,
        this.selectedTimeSlot.slotId, 
        this.eventCycle
      )
    ).val();

    if (
      currentSlotData?.indID &&
      currentSlotData?.status_appointment === SlotStatus.CONFIRMED
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'This slot is not available. Please choose another slot.',
        life: 4000,
      });
      throw new Error('Slot is already booked');
    }

    this.loaderService.show();
    try {
      // Check if 'ind' already has a slot assigned.
      await this.removeExistingInd(this.indId);
      let slotStartTimeString = this.selectedTimeSlot
      ? this.commonFun.moment(this.selectedTimeSlot.millisecond).format(
        `h:mm a z [on] MMMM D, YYYY`
      )
      : null;

      // Add ind data in slot
      await this.eventService
        .updateSlotData(
          this.studioId,
          this.orgId,
          this.eventId,
          this.selectedTimeSlot.slotId,
          this.indId,
          SlotStatus.CONFIRMED,
          slotStartTimeString,
          this.eventCycle
        )
        .then(() => {
          this.pickNewTime = false;
          this.confirmSitting = true;
          this.clearErrorsMsgAndRefreshSlotData();
        });
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong. Please try again later.',
        life: 4000,
      });
      console.error('Error confirming cancellation status:', error);
    }
  }

  async clearErrorsMsgAndRefreshSlotData() {
    this.isShowErrorMassage = false;
    this.selectedPreferredDate = null;
    this.selectedTimeSlot = null;
    this.slotPreferredTime = [];
    await this.getCurrentSlotData();
  }

  async confirmSlot() {
    this.loaderService.show();
    if (!this.currentSlotData) {
      this.messageService.add({
        severity: 'info',
        summary: 'Error',
        detail:
          'You currently do not have any assigned slot. Please select a slot to proceed.',
        life: 5000,
      });
      this.loaderService.hide();
      return;
    }
    try {
      // Check if the slot is already booked or not
      const currentSlotData = (
        await this.eventService.getSlotById(
          this.studioId,
          this.orgId,
          this.eventId,
          this.currentSlotData.key, 
          this.eventCycle
        )
      ).val();
      if (
        currentSlotData?.indID &&
        currentSlotData?.status_appointment === SlotStatus.CONFIRMED
      ) {
        this.loaderService.hide();
        if (currentSlotData?.indID == this.currentSlotData?.indID) {
          this.messageService.add({
            severity: 'info',
            summary: 'Error',
            detail: 'You have already confirmed a slot.',
            life: 4000,
          });
          return;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'This slot is not available. Please choose another slot.',
            life: 4000,
          });
          throw new Error('Slot is already booked');
        }
      }

      // Update the slot data if it exists or if isDirectUpdate is true
      // Check if 'ind' already has a slot assigned.
      await this.removeExistingInd(this.indId);
      if (this.currentSlotData?.indID || this.isDirectUpdate) {
        const slotId = this.currentSlotData.key;
        const slotStartTimeString = this.currentSlotData
          ? this.commonFun.moment(this.currentSlotData.startTime).format(
              'h:mm a z [on] MMMM D, YYYY'
            )
          : null;

        await this.eventService.updateSlotData(
          this.studioId,
          this.orgId,
          this.eventId,
          slotId,
          this.indId,
          SlotStatus.CONFIRMED,
          slotStartTimeString,
          this.eventCycle
        );

        // Set confirmSitting to true after successful confirmation
        this.confirmSitting = true;
        this.loaderService.hide();
      }
    } catch (error) {
      this.loaderService.hide();
      console.error('error: ', error);
      this.loaderService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong. Please try again later.',
        life: 4000,
      });
    }
  }

  async confirmCancellationStatus() {
    try {
      this.loaderService.show();
      let slotId = this.currentSlotData.key;
      await this.eventService.updateSlotData(
        this.studioId,
        this.orgId,
        this.eventId,
        slotId,
        this.indId,
        SlotStatus.DECLINED,
        null,
        this.eventCycle
      );
      this.cancelSitting = true;
      this.confirmCancellation = true;
      this.loaderService.hide();
      await this.clearErrorsMsgAndRefreshSlotData();
    } catch (error) {
      console.error('Error confirming cancellation status:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong. Please try again later.',
        life: 4000,
      });
    }
  }

  async removeExistingInd(indId) {
    if (this.currentSlotData?.indID) {
      for (const slot of this.allSlots) {
        if (slot.indID === indId) {
          const slotPath = `/portal/events/${this.studioId}/${this.orgId}/${this.eventCycle}/${this.eventId}/slots/${slot.key}`;
          this.db.object(`${slotPath}`).update({
            indID: null,
            slotStartTimeString: null,
            status_appointment: null,
            status_sit: 'future',
          });
        }
      }
    }
  }

  onCancelSlotBooking() {
    this.pickNewTime = false;
    this.isShowErrorMassage = false;
    this.clearErrorsMsgAndRefreshSlotData();
  }

  dateFormate(timestamp: number | string | MomentInput, format: string){
    if (timestamp) {
      return this.commonFun.moment(timestamp).format(format);
    } else {
      return null;
    }
  }
}
